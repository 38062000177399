#messages {
  .messageTable {
    .messageTableEntry {
      display: grid;
      grid-template-columns: 6fr 1fr;
      grid-column-gap: var(--gutter);
      a:hover {
        text-decoration: underline;
      }
      p {
        margin: 0;
        line-height: 1.2;
        margin-bottom: calc(var(--gutter) / 2);
      }
      border: 1px solid transparent;
      &:not(.messageTableHead) {
        &:hover {
          cursor: pointer;
          border: 1px solid $cg-blue;
        }
      }
      .messageHeader {
        font-weight: bold;
        margin-bottom: calc(var(--gutter) / 4);
        .fromBuyer,
        .unread {
          margin-right: 5px;
        }
      }
      .reference {
        .buttons {
          button {
            margin-bottom: calc(var(--gutter) / 2);
          }
        }
      }
      &.messageTableHead {
        font-weight: bold;
      }
    }
  }
}
