.listing {
  &.readOnly {
    color: $cg-grey-light;
  }
  &.selected {
    border: 1px solid $cg-blue;
    border-radius: $border-radius;
    padding: calc(var(--gutter) / 2);
  }
  .listingHeader {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 20px 0.3fr 1fr 1fr 1.5fr;
    @include for-phone-only {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--gutter);
    }
    color: $cg-grey;
    p {
      margin-bottom: calc(var(--gutter) / 6);
    }
    justify-content: space-between;
    h2 {
      margin-top: 0;
    }
    span.title {
      font-weight: 600;
    }
    .description {
      .priceAndLogs {
        .compared {
          text-decoration: line-through;
        }
      }
    }
    .bulkAndIndicator {
      display: flex;
      height: 100%;
      width: 30px;
      flex-direction: column;
      @include for-phone-only {
        flex-direction: row;
      }
      justify-content: flex-start;
      align-items: center;
      .bulkBox {
        @include for-phone-only {
          margin-left: 10px;
        }
        input {
          margin-right: 0;
        }
      }
    }
    .image {
      img {
        width: 100%;
        height: auto;
        @include for-phone-only {
          width: 100%;
        }
      }
    }
    .indicator {
      svg {
        width: 13px;
        height: 13px;
        visibility: hidden;
      }
    }
    .lock {
      margin-right: 5px;
    }
    .inStock,
    .soldOut {
      width: 11px;
      height: 11px;
      align-self: center;
      margin: 10px 0px;
      border-radius: 50%;
      border: 1px solid black;
    }
    .soldOut {
      border-color: $cg-red;
      background-color: $cg-orange;
    }
    .inStock {
      border-color: $cg-green;
      background-color: $cg-green;
    }
    .headerRight {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      @include for-phone-only {
        align-items: initial;
      }
      .buttons {
        display: flex;
        @include for-phone-only {
          flex-direction: row;
          justify-content: space-between;
        }
        .discogs {
          margin-right: 2em;
        }
        button,
        a {
          margin: 0;
          margin-left: 1em;
        }
        @include respondToMax(lg) {
          .discogs,
          button,
          a {
            margin: 0;
            margin-left: 0;
          }
        }
      }
      .discogs {
        button,
        a,
        p {
          margin: 0;
          margin-bottom: 0.5em;
        }
      }
    }
  }
  .listingContent {
    font-size: $text-sm;
    &.hide {
      display: none;
      opacity: 0;
    }
    &.show {
      padding: var(--gutter) 0;
    }
    label {
      display: block;
    }
    #columns {
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: auto auto auto;
      grid-column-gap: calc(var(--gutter) * 2);
      @include respondToMin(lg) {
        grid-template-columns: 1fr 1fr;
      }
      @include respondToMin(lg) {
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr;
      }
      opacity: 1;
      transition: opacity 1s ease-out;

      .priceAndTax {
        .price {
          position: relative;
          .priceHeader {
            position: absolute;
            right: var(--gutter);
            top: -5px;
          }
        }
      }
      .priceAndTax,
      .comments,
      .conditionsAndDate,
      .availableAndCategory,
      .stockLocationSupplier {
        display: flex;
        flex-direction: column;
        & > * {
          margin-bottom: calc(var(--gutter) / 1);
        }
      }
      .cg-checkbox {
        margin-top: calc(var(--gutter) * 1);
      }
      .options {
        display: grid;
        grid-gap: calc(var(--gutter) / 2);
        .option {
          display: grid;
          grid-gap: calc(var(--gutter) / 2);
          grid-template-columns: 1fr 1fr 0.2fr;
          align-items: center;
          button {
            margin-top: 20px;
            padding: 5px;
          }
        }
      }
      .templateControls {
        .editTemplate {
          display: flex;
          justify-content: flex-end;
          margin: 5px 0;
        }
        .cg-select,
        .cg-checkbox {
          margin-bottom: calc(var(--gutter) / 2);
        }
      }
    }
    .submit {
      display: flex;
      flex-direction: column;
      @include respondToMin(lg) {
        justify-content: flex-end;
        grid-column-start: 5;
        flex-direction: row;
      }
      button {
        margin-left: var(--gutter);
        @include for-phone-only {
          margin-bottom: var(--gutter);
        }
      }
    }
  }
}
