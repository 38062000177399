#vouchers {
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    #headerButtons {
      display: flex;
      flex-direction: row;
      button {
        margin-left: calc(var(--gutter) / 2);
      }
    }
    .left {
      display: flex;
      align-items: center;
      h1 {
        margin-right: var(--gutter);
      }
    }
  }
  #vouchersFilters {
    margin-bottom: var(--gutter);
    display: grid;
    grid-gap: var(--gutter);

    @include respondToMin(lg) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-flow: column;
      grid-auto-columns: auto;
    }
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }

    .column {
      .column-item:not(:first-child) {
        margin-top: 2em;
      }
    }
    p {
      font-weight: bold;
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
  #vouchersTable {
    .voucherEntry {
      p {
        margin: 0;
      }
      display: grid;
      align-items: center;
      grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr 1fr 1fr 0.5fr;
      grid-column-gap: calc(var(--gutter) / 2);
      &.header {
        font-weight: bold;
      }
      .expired {
        color: $cg-red;
      }
      .buttons {
        & > * {
          margin-bottom: var(--gutter);
        }
      }
      @include respondToMax(lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
        .created,
        .value,
        .type {
          display: none;
        }
      }
    }
  }
}

#voucherGenerator {
  display: grid;
  grid-gap: calc(var(--gutter) / 1);
  .value {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-start;
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    .valueColumns {
      display: grid;
      grid-template-columns: 50% 50%;
    }
    grid-gap: var(--gutter);
  }
}
