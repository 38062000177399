#userTable {
  display: grid;
  .userTableEntry {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.8fr;
    @include respondToMax(lg) {
      grid-template-columns: 1fr 1fr;
      .address,
      .lastLogin,
      .lists {
        display: none;
      }
    }
    .lists {
      button {
        margin-bottom: var(--gutter);
      }
    }
    &.header {
      font-weight: bold;
      margin: 0;
    }
    p {
      margin: 0;
      &.telephone {
        margin-top: 5px;
      }
    }
  }
}

#userCreate {
  #userCreateForm {
    display: grid;
    grid-gap: var(--gutter);
    .createUserDetails {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: var(--gutter);
      @include respondToMax(lg) {
        grid-template-columns: 1fr;
      }
    }
    #addToList {
      display: block;
      label {
        display: flex;
        flex-direction: row;
      }
      #listsList {
        display: flex;
        flex-direction: row;
        label {
          margin-right: var(--gutter);
        }
      }
    }
    #createUserButtons {
      display: flex;
      justify-content: space-between;
    }
    .addresses {
      display: grid;
      grid-template-columns: 1fr;
      .address {
        fieldset {
          display: grid;
          grid-gap: var(--gutter);
        }
      }
    }
  }
  #searchOrCreate {
    display: grid;
    grid-template-columns: 4fr 1fr;
    input,
    button {
      height: 35px;
      margin: 0;
    }
    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    }
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

#users {
  & > .header {
    display: flex;
    align-items: center;
    .left {
      display: flex;
      @include respondToMax(lg) {
        display: block;
      }
      align-items: center;
      h1 {
        margin-right: calc(var(--gutter) / 2);
      }
    }
    .right {
      display: flex;
      align-items: center;
      @include lessThan(lg) {
        flex-direction: column;
        button {
          margin-top: var(--gutter);
        }
      }
      button {
        margin-left: var(--gutter);
      }
      button > .loader {
        margin: 0;
        padding: 0;
        font-size: $font-size-sm;
      }
    }
    justify-content: space-between;
  }

  .usersSearch {
    width: 330px;
    @include respondToMax(lg) {
      width: 100%;
      margin-left: 0;
    }
  }

  #usersFilters {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: calc(var(--gutter) * 2);
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    grid-gap: var(--gutter);
    .listFilter {
      min-width: 200px;
    }
    #lists {
      button {
        margin: 0;
      }
      #listsView {
        padding: 0 var(--gutter);
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(4, 1fr);
        @include respondToMax(lg) {
          grid-template-columns: 1fr;
          padding: 0;
          margin-top: var(--gutter);
        }
        .list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .listHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            button {
              margin: 0;
            }
          }
        }
      }
      display: flex;
      @include respondToMax(lg) {
        display: block;
      }

      #newListForm {
        margin-top: var(--gutter);
      }
      #listFilterAndNew,
      #listFilterAndNew label {
        display: flex;
        flex-direction: column;
        & button {
          margin-top: calc(var(--gutter) / 2);
          display: block;
          width: 100%;
        }
      }
    }
  }
}

#user {
  .header {
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      display: flex;
      align-items: center;
      h1,
      p,
      button {
        margin: 0;
      }
    }
    .left {
      button {
        border: none;
        margin-left: var(--gutter);
      }
    }
  }
  .userTabs {
    .react-tabs__tab-list {
      padding: 0 var(--gutter);
    }
    .content {
      display: grid;
      grid-gap: var(--gutter);
      .topButtons {
        padding: var(--gutter);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  #lists {
    #listButtons {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  #editUser {
    .header {
      margin: calc(var(--gutter) / 2) 0;
    }
    .content {
      display: grid;
      grid-template-columns: 1fr 2fr;
      @include respondToMax(lg) {
        grid-template-columns: 1fr;
      }
      grid-gap: var(--gutter);

      .left {
        & > * {
          margin: var(--gutter) 0;
        }
      }
      .right {
      }
    }
    #fields {
      margin: var(--gutter) 0;
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--gutter);
      @include respondToMax(lg) {
        grid-template-columns: 1fr;
      }
    }
  }
  .addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    grid-gap: var(--gutter);
    .address {
      display: grid;
      grid-gap: var(--gutter);
      fieldset {
        display: grid;
        grid-gap: var(--gutter);
        border: 0;
        padding: 0;
      }
      .addressInline {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 1fr 1fr;
        @include respondToMax(lg) {
          grid-template-columns: 1fr;
        }
        grid-column-gap: calc(var(--gutter) / 2);
      }
    }
  }
}
