#productPage {
  #productHeader {
    display: flex;
    justify-content: space-between;
    @extend .contentHeader;
    button {
      margin-left: var(--gutter);
    }
    #releaseHeaderRight {
      flex-wrap: nowrap;
      display: flex;
    }
  }
  #informations {
    h2 {
      font-size: $font-size-md;
    }
    display: grid;
    grid-template-columns: 1fr 2fr 2fr;
    grid-column-gap: var(--gutter);
    #left {
      img#mainImage {
        width: 100%;
      }
      .images {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        img {
          margin-top: 1em;
          max-width: 45%;
          height: auto;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    #middle {
      .productInfo {
        font-weight: bold;
        span {
          font-weight: normal;
        }
      }
      #description {
        margin-top: 2em;
        p {
          text-align: justify;
        }
      }
    }
  }
  #listings {
    margin-top: var(--gutter);
    #listingsHeader {
      h2 {
        margin: 0;
      }
      display: flex;
      justify-content: space-between;
    }
  }
  #statistics {
    #statisticsHeader {
      @extend .contentHeader;
      justify-content: space-between;
    }
  }
}

#productEdit {
  #header {
    @extend .contentHeader;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h1,
    button,
    p {
      margin: 0;
    }

    #headerButtons,
    #headerLeft {
      display: flex;
      align-items: center;
      button {
        margin-left: 1em;
      }
    }
  }
  #product {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    grid-gap: var(--gutter);
    #images {
      .entries {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 1em;
        grid-row-gap: 1em;
        .image {
          position: relative;
          &:hover {
            .hover {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .hover {
            display: none;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0.8;
            cursor: grab;
            button {
              &:hover {
                cursor: pointer;
              }
              max-width: 100px;
              opacity: 1;
            }
            background-color: white;
          }
        }
      }
      button {
        margin-top: 2em;
        margin-bottom: 0;
        display: block;
        width: 100%;
      }
      #imageDropzone {
        margin-bottom: var(--gutter);
        border: 2px dotted $cg-black;
        text-align: center;
        padding: var(--gutter);
      }
    }
    #information {
      #fields {
        display: grid;
        grid-auto-columns: auto auto;
        grid-template-columns: 1fr;
        @include respondTo(lg) {
          grid-template-columns: 1fr 1fr;
        }
        grid-gap: var(--gutter);
        label {
          display: flex;
          flex-direction: column;
        }
      }

      p.headerInfoText {
        margin-bottom: 0;
      }
    }
  }
  #listings {
    margin-top: var(--gutter);
  }
}
