#article {
  .content {
    .rows {
      .row {
        margin-bottom: calc(var(--gutter));
        &.focused {
          box-shadow: -2px 2px 10px $cg-grey;
        }
        &:not(.fullWidth) {
          max-width: 80%;
          margin: var(--gutter) auto;
        }
        &:not(.noPadding) {
          .columns {
            padding: var(--gutter);
          }
        }
        & > .header {
          display: flex;
          justify-content: space-between;
          padding: calc(var(--gutter));
          .options {
            display: flex;
            align-items: center;
            label {
              font-size: $font-size-sm;
              display: flex;
              align-content: center;
              margin-left: var(--gutter);
            }
          }
        }
        button.delete {
          margin-left: calc(var(--gutter) * 2);
        }
        .column {
          background-color: transparent;
          .footer {
            text-align: center;
            padding: calc(var(--gutter) * 1) 0;
            button {
              &:hover {
                color: black;
              }
            }
          }
        }
        .blockProposal {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: calc(var(--gutter) * 2) 0;
          .entry {
            text-align: center;
            button {
              &[disabled] {
                color: $cg-grey;
              }
              margin-bottom: calc(var(--gutter));
              font-size: $font-size-lg;
            }
          }
        }
        &.twoColumn,
        &.threeColumn {
          .columns {
            grid-gap: calc(var(--gutter));
          }
          &.noPadding > .columns {
            grid-gap: 0;
          }
        }
        &.twoColumn {
          .columns {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }
        &.threeColumn {
          .columns {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            min-height: 20px;
          }
        }
      }
    }
  }
}

.block {
  .toolbar {
    z-index: 99;
    width: 100%;
    visibility: hidden;
    padding: calc(var(--gutter) / 2) 0;
    display: flex;
    justify-content: center;
    margin-top: calc(var(--gutter) / 2);
    button {
      font-weight: bold;
    }
  }
  &.isEditing {
    padding: var(--gutter);
  }
  &:hover,
  &.isEditing {
    .toolbar {
      visibility: visible;
    }
  }
  &.image {
    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      button {
        font-size: $font-size-lg;
      }
      .linkAndFormat {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--gutter);
        margin-top: var(--gutter);
        label {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  &.embed {
  }
  &.items {
    .editor {
      & > * {
        margin-bottom: var(--gutter);
      }
      .options {
        display: flex;
        justify-content: space-between;
      }
      align-items: flex-end;
    }
    .preview {
      .entries {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(4, 1fr);
        .entry {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
  &.text {
    .quillEditor {
      min-height: 100px;
      .ql-editor.ql-blank::before {
        color: inherit;
      }
    }
    .quillEditor,
    .ql-toolbar {
      display: none;
    }
    .preview {
      display: block;
    }
    &.isEditing {
      .quillEditor,
      .ql-toolbar {
        display: block;
      }
      .preview {
        display: none;
      }
    }
  }
  &.subscribe {
    .content {
      text-align: center;
      button.list {
        margin: var(--gutter);
      }
    }
  }
  &.separator {
    text-align: center;
    color: $cg-grey;
    hr {
      border-color: black;
    }
  }
  &.carousel {
    .content {
      .entries {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(3, 1fr);
        .entryHeader {
          p {
            margin: 0;
          }
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
        .linkAndFormat {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: var(--gutter);
          margin-top: var(--gutter);
        }
      }
      .addEntry {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: calc(var(--gutter) * 2);
        button {
          margin-bottom: 10px;
          height: auto;
        }
      }
      .carouselContainer {
        .slick-slider {
          overflow: hidden;
          min-width: 100%;
          width: 0;
          .slick-list {
            width: 100%;
          }
          .slick-dots {
            display: flex !important;
            justify-content: center;
            display: inline-block;
            vertical-align: middle;
            margin: var(--gutter) 0;
            padding: 0;
            text-align: center;
            li {
              &.slick-active {
                button {
                  background-color: black;
                }
              }
              button {
                width: 10px;
                height: 10px;
                border: none;
                background: $cg-grey;
                color: transparent;
                cursor: pointer;
                display: block;
                padding: 0;
                border-radius: 20px;
              }
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
  &.playlist {
    .playlistComponent {
      h2 {
        margin-bottom: var(--gutter);
      }
      .entries {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(4, 1fr);
      }
    }
    h2.notice {
      text-align: center;
    }
    .content {
      display: flex;
      justify-content: center;
      .selectPlaylist {
        font-size: $font-size-lg;
      }
    }
    .playlistSelector {
      h2 {
        text-align: center;
        margin-bottom: var(--gutter);
      }
      .content {
        display: flex;
        flex-direction: column;
        button {
          margin-bottom: var(--gutter);
        }
      }
    }
  }
  &.collection {
    .collectionEditor {
      .parameters {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--gutter);
      }
      .content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: var(--gutter);
      }
    }
    .collectionComponent {
      h2 {
        margin-bottom: var(--gutter);
      }
      .entries {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(4, 1fr);
      }
      .footer {
        text-align: center;
      }
    }
    h2.notice {
      text-align: center;
    }
    .content {
      display: flex;
      justify-content: center;
      .selectPlaylist {
        font-size: $font-size-lg;
      }
    }
    .playlistSelector {
      h2 {
        text-align: center;
        margin-bottom: var(--gutter);
      }
      .content {
        display: flex;
        flex-direction: column;
        button {
          margin-bottom: var(--gutter);
        }
      }
    }
  }
  &.blog {
    &.blogEditor {
      margin: var(--gutter) 0;
      padding: var(--gutter);
      .options {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 1fr 1fr;
        & > div > * {
          margin-bottom: var(--gutter);
        }
      }
    }
    .columns {
      &.columns-2 {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(2, 1fr);
      }
      &.columns-3 {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}
