#editTemplate {
  & > .header {
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      display: flex;
      align-items: center;
      button {
        margin-left: var(--gutter);
      }
    }
  }
  form {
    display: flex;
    justify-content: space-between;
    .title,
    .type {
      display: flex;
      flex-direction: column;
      margin-right: 1em;
    }
    .select {
      min-width: 200px;
    }
    .left {
      display: flex;
      flex-direction: row;
      @include lessThan(md) {
        flex-direction: column;
      }
      justify-content: space-between;
    }
    .right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h2 {
      margin-bottom: 0.5em;
      margin-top: 0;
    }
    button {
      margin-left: 1em;
    }
  }
  .editor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    #codeEditor {
      max-height: calc(80vh);
      overflow-y: scroll;
      @include lessThan(md) {
        grid-template-columns: calc(100vw - var(--gutter) * 2);
      }
    }
    #codePreview {
      max-height: calc(80vh);
      .header {
        h3 {
          text-transform: uppercase;
          margin: 0;
        }
        padding: var(--gutter);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select {
          min-width: 100px;
        }
      }
      iframe {
        height: 100%;
        background-color: $cg-grey-lightest;
        width: 100%;
      }
    }
  }

  .modalFooter {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
  }
}

#templates {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .types {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gutter);
    @include lessThan(md) {
      grid-template-columns: 1fr;
    }
    .type {
      margin-bottom: var(--gutter);
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: calc(var(--gutter) / 2);
      & > .header {
        display: flex;
      }
      .entries {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--gutter);
        .template {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: var(--gutter);
          .actionsContainer {
            justify-self: flex-end;
            display: flex;
            align-items: center;
            .actions {
              p {
                margin-bottom: 0;
              }
              display: flex;
              align-items: center;
              & > * {
                margin-left: var(--gutter);
              }
            }
          }
        }
        hr {
          margin-top: 0;
        }
        .creator {
          font-size: $font-size-sm;
        }
      }
    }
  }
  .templateMapping {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
    }
  }
}

#newTemplateModal {
  hr {
    margin-bottom: calc(var(--gutter) * 2);
  }
  .content {
    h2,
    .cg-select,
    .cg-input {
      margin-bottom: calc(var(--gutter) * 2);
    }
    .versions {
      overflow-y: scroll;
      .version {
        margin-bottom: calc(var(--gutter) * 2);
      }
    }
  }
}
