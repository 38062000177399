.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.left-\[5px\] {
  left: 5px;
}
.top-\[5px\] {
  top: 5px;
}
.m-0 {
  margin: 0;
}
.flex {
  display: flex;
}
.h-10 {
  height: 2.5rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-\[300px\] {
  height: 300px;
}
.h-\[calc\(100\%\+11px\)\] {
  height: calc(100% + 11px);
}
.h-full {
  height: 100%;
}
.w-10 {
  width: 2.2rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-\[calc\(100\%\+11px\)\] {
  width: calc(100% + 11px);
}
.w-full {
  width: 100%;
}
.max-w-md {
  max-width: 28rem;
}
.cursor-pointer {
  cursor: pointer;
}
.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.justify-center {
  // justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.gap-12 {
  gap: 3rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-8 {
  gap: 2rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.border {
  border-width: 1px;
}
.border-0 {
  border-width: 0px;
}
.border-b-0 {
  border-bottom-width: 0px;
}
.border-l-0 {
  border-left-width: 0px;
}
.border-r-0 {
  border-right-width: 0px;
}
.border-dashed {
  border-style: dashed;
}
.border-none {
  border-style: none;
}
.border-gray-500\/50 {
  border-color: #6b728080;
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: $cg-grey-dark;
}
.bg-transparent {
  background-color: transparent;
}
.p-4 {
  padding: 1rem;
}
.pt-4 {
  padding-top: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
.text-slate-900 {
  --tw-text-opacity: 1;
  color: $cg-grey-light;
}
.opacity-30 {
  opacity: 0.3;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}
.hover\:z-20:hover {
  z-index: 20;
}
.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  // background-color: $cg-grey;
}
.hover\:transition-none:hover {
  transition-property: none;
}
.focus\:bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}
.group:hover .group-hover\:opacity-70 {
  opacity: 0.7;
}
:is(.dark .dark\:bg-gray-800) {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}
:is(.dark .dark\:bg-transparent) {
  background-color: transparent;
}
:is(.dark .dark\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
:is(.dark .dark\:hover\:bg-gray-700:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}
:is(.dark .dark\:focus\:bg-gray-900:focus) {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}
