#footer {
  .centralContainer {
    border-top: 1px dashed $cg-grey-dark;
    padding: var(--gutter);
    align-items: center;
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 2fr;
    height: 100%;
    img {
      max-width: 170px;
    }
    #footerRight {
      margin-left: auto;
      @include respondToMax(lg) {
        display: none;
      }
    }
    #footerLeft,
    #footerRight {
      font-size: $font-size-md;
      p {
        margin: 0;
        padding: 0;
        margin-block-end: 0;
        margin-block-start: 0;
      }
      ul {
        display: flex;
        align-items: flex-end;
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          padding: 0 calc(var(--gutter) / 2);
          #footerSupport {
            display: none;
            @include respondTo(lg) {
              display: block;
            }
          }
        }
      }
    }
  }
}
