#creditNotes {
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filters {
    display: flex;
    justify-content: flex-start;
    grid-gap: var(--gutter);
    margin-bottom: calc(var(--gutter) * 2);
    & > * {
      margin-right: var(--gutter);
    }
    @include respondToMax(lg) {
      flex-direction: column;
    }

    .columns {
      .column-item:not(:first-child) {
        margin-top: var(--gutter);
      }
    }
    p {
      font-weight: bold;
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
  .table {
    .getStarted {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .button {
        text-align: center;
      }
      padding: var(--gutter);
      h3,
      button {
        margin-top: var(--gutter);
        text-align: center;
        border: none;
      }
    }

    padding: 0;
    & > .entry {
      p {
        margin: 0;
      }
      &.void {
        color: grey;
      }
      display: grid;
      align-items: center;
      grid-template-columns: 2fr 0.5fr 0.5fr 2fr 1fr 1fr 0.5fr;
      grid-column-gap: calc(var(--gutter) / 2);
      &.header {
        font-weight: bold;
      }
    }
  }
}

.viewCreditNoteDetails {
  .modalHeader {
    display: flex;
    justify-content: space-between;
  }
  .content {
    & > * {
      margin-bottom: calc(var(--gutter));
    }
    & > .header {
      display: grid;
      grid-gap: calc(var(--gutter) * 2);
      grid-template-columns: 1fr 1fr 1fr;
    }
    .items {
      .header {
        font-weight: bold;
      }
      .entry,
      .header {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 2fr 0.5fr 0.5fr 0.5fr 0.5fr;
        padding: 5px;
        p {
          margin: 0;
        }
        align-items: center;
        p:last-child {
          text-align: right;
        }
      }
    }
    .totals {
      display: flex;
      justify-content: flex-end;
      .list {
        text-align: right;
        display: flex;
        flex-direction: column;
      }
    }
    .spendings {
      display: flex;
      justify-content: space-between;
      .list {
        text-align: right;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

#creditNoteCreate {
  .inline {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--gutter);
    & > * {
      margin-bottom: calc(var(--gutter) * 2);
    }
  }
  label > input {
    display: flex;
    flex-direction: column;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
  .user {
    margin-top: 5px;
  }
  .items {
    .header {
      margin-bottom: var(--gutter);
      h2 {
        width: auto;
      }
      .listingSearch {
        width: 80%;
      }
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      button {
        border: none;
        margin: 0;
      }
    }
    .entries {
      .entry {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
        align-items: center;
        .taxes {
          .taxEntry {
            display: flex;
          }
        }
        input {
          margin-top: 5px;
        }
      }
    }
  }
  .footer {
    align-items: center;
    label {
      display: flex;
    }
  }
}
