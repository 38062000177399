#media {
  & > .header {
    display: flex;
    justify-content: space-between;
  }
}

#mediaBrowser {
  display: grid;
  grid-gap: var(--gutter);
  .noMedia {
    text-align: center;
  }
  .files {
    display: grid;
    grid-gap: calc(var(--gutter) * 2);
    grid-template-columns: repeat(4, 1fr);
    @include lessThan(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    .entry {
      &:hover {
        cursor: pointer;
      }
      background-clip: padding-box;
      border: none;
      display: grid;
      grid-gap: 10px;
      p {
        font-size: $font-size-sm;
        margin: 0;
        word-wrap: break-word;
        white-space: normal;
      }
      .image {
        height: 0px;
        padding-bottom: 160px;
        position: relative;
        .imageContainer {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          background-color: $cg-grey-dark;
          border-radius: 2px;
        }
        img {
          display: block;
          max-width: 100%;
          max-height: 100%;
          width: auto;
          height: auto;
          margin: auto;
        }
        .checkboxSelector {
          position: absolute;
          left: var(--gutter);
          top: var(--gutter);

          input {
            width: 15px;
            height: 15px;
          }
        }
        &:not(:hover) {
          input:not(:checked) {
            visibility: hidden;
          }
        }
        &:hover {
          .checkboxSelector {
            visibility: visible;
          }
          transform: scale(1.01);
        }
      }
      .details {
        display: grid;
        grid-gap: 5px;
        .titleAndType {
          @include lessThan(lg) {
            display: none;
          }
          display: flex;
          justify-content: space-between;
          .tag {
            font-size: $font-size-xs;
            padding: 2px 10px;
            color: $cg-blue;
            border-color: $cg-blue;
            text-transform: uppercase;
          }
        }
        .tech {
          display: flex;
          justify-content: space-between;
          color: $cg-grey;
        }
        button {
          width: 100%;
        }
      }
    }
  }
  .pagination {
    .right {
      li {
        padding: 5px;
      }
      button,
      span {
        &.active {
          font-weight: bold;
        }
        padding: 5px;
        font-size: $font-size-md;
      }
      button {
        border: none;
      }
    }
  }
  .header {
    p {
      margin: 0;
    }
    display: flex;
    justify-content: space-between;
    .left {
      & > * {
        margin-right: var(--gutter);
      }
      button {
        margin: 0;
      }
      display: flex;
      align-items: center;
    }
  }
}

#mediaDetails {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr 1fr;
  @include lessThan(lg) {
    grid-template-columns: 1fr;
  }
  .left {
    .image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      background-color: rgb(51, 55, 64);
      border-radius: $border-radius;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        margin: auto;
      }
      i {
        width: 50%;
        height: 50%;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .right {
    display: grid;
    grid-gap: var(--gutter);
    .specs {
      border-radius: $border-radius;
      padding: var(--gutter);
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
    }
    form.detailsForm {
      display: grid;
      grid-gap: var(--gutter);
      label {
        input,
        small {
          margin-top: 5px;
        }
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.mediaModal {
  position: absolute;
  top: 10vh;
  left: 20%;
  right: 20%;

  @include lessThan(lg) {
    left: 5%;
    right: 5%;
    width: 90%;
  }
  max-height: 80vh;
  overflow: hidden;
  overflow-y: scroll;
  &:focus {
    outline: 0;
  }
  border: 0 !important;
  padding: 0 !important;
  border-radius: $border-radius;
  background-color: white;
  .header {
    h2 {
      margin: 0;
    }
    .left {
      display: flex;
    }
  }
  .mediaDetailsModal,
  .mediaUploadModal,
  .mediaSelectorModal {
    border: none;
    display: grid;
    .content {
      padding: var(--gutter);
    }
    .footer {
      padding: var(--gutter);
      display: flex;
      justify-content: space-between;
      .danger {
        margin-right: 30px;
      }
    }
  }
  .mediaUploadModal {
    .header,
    .footer {
      button {
        border: none;
        background-color: transparent;
      }
    }
  }
  .mediaSelectorModal {
    .content {
      max-height: 60vh;
      overflow: hidden;
      overflow-y: scroll;
    }
  }
}

#mediaUploader {
  .content {
    text-align: center;
    svg {
      min-width: 50px;
      height: auto;
    }
  }
  .dropzone {
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: $cg-blue;
    border-style: dashed;
    outline: none;
    transition: border 0.24s ease-in-out;
    .files {
      p {
        text-align: center;
        margin: 0;
      }
      .file {
        display: grid;
        grid-gap: var(--gutter);
        align-content: center;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10px;
        .progress {
          border: 1px solid $cg-grey-lightest;
          background-color: white;
          .bar {
            background-color: $cg-green;
            height: 100%;
          }
        }
      }
    }
  }
}

.mediaOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $cg-grey-dark;
}

.mediaSelector {
  height: 15rem;
  position: relative;
  background-color: $cg-grey-dark;
  .content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    .placeholder {
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      flex-direction: column;
      p {
        text-align: center;
        color: white;
      }
    }
    .image {
      background-color: inherit !important;
      height: 100%;
      display: flex;
      .toolbox {
        display: flex;
        align-content: flex-end;
        justify-content: flex-end;
        position: absolute;
        padding: 10px;
        right: 0px;
        top: 0px;
        .tool {
          cursor: pointer;
          background-color: transparent;
          border: none;
          svg {
            width: 20px;
            height: auto;
            color: white;
          }
        }
      }
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: 100%;
        margin: auto;
      }
    }
  }
}
