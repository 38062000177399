#mainMenu {
  flex-shrink: 0;
  flex-grow: 0;
  height: calc(100% - var(--navbarHeight));
  position: fixed;
  top: var(--navbarHeight);
  padding: 0;
  z-index: 2;
  * {
    box-sizing: border-box;
  }
  .spacer {
    margin-top: var(--navbarHeight);
  }
  border-radius: 0;
  &.mobile {
    display: none;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ol {
    li.helpMenuButton {
      .toggleBottomEntriesBtn {
        padding: calc(var(--gutter) / 2);
        padding-left: 3px;
      }
    }
  }
  &.open {
    flex-basis: auto;
    & > #menu > .entries {
      & > ol > li:not(.hasSubmenu),
      .submenu {
        width: 100%;
        padding-right: var(--gutter);
      }
    }
  }
  &.collapsed {
    .logoLink {
      display: none;
    }
    #menu {
      width: var(--menuSizeCollapsed);
    }
    & > #menu > .entries {
      & > ol {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        li > a,
        li > button,
        li span,
        li {
          > span {
            display: none;
          }
        }
      }
      i {
        margin-right: 0px;
      }
      ol li .submenu {
        li {
          overflow: visible;
          padding: 0;
        }
        i {
          visibility: visible;
        }
      }
      .bottomEntriesDropdown {
        span {
          display: block;
        }
      }
      .toggleBottomEntriesBtn {
        padding-right: 0;
      }
    }
  }
  #menu {
    width: var(--menuSize);
    height: calc(100vh - var(--navbarHeight));
    height: calc(100dvh - var(--navbarHeight));
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    transition: width 0.2s ease;

    .entries {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      max-width: 100%;
      height: 100%;
      hr {
        margin: 10px 0px;
        &.invisible {
          visibility: hidden;
        }
      }
      > ol {
        &:first-child {
          padding: var(--gutter);
          min-height: 0;
          flex: 1 0 auto;
          list-style: none;
          padding-bottom: var(--gutter);
          overflow-y: scroll;
          height: calc(100% - var(--bottomEntriesBtnHeight));
          min-width: 62px;
          -ms-overflow-style: none;
          scrollbar-width: none;

          &::-webkit-scrollbar {
            width: 0px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: transparent;
          }

          &.isCollapsed {
            scrollbar-width: none;
            -ms-overflow-style: auto;
            scrollbar-width: auto;
          }
        }
        li {
          max-width: 100%;
          font-size: 15px;
          display: grid;
          grid-template-rows: 1fr 0;
          grid-template-columns: 1fr;
          align-items: baseline;
          white-space: nowrap;
          transition: all 0.2s ease;
          a {
            display: flex;
            align-items: center;
            line-height: 0.8;
            padding-right: var(--gutter);
            position: relative;
            max-width: 100%;

            &:hover {
              text-decoration: none;
            }
          }
          i:not(.external) {
            font-size: 20px;
          }
          i.external {
            font-size: $text-sm;
          }
          .icon-wrapper {
            border-radius: calc(var(--gutter) / 3);
            display: grid;
            place-items: center;
            aspect-ratio: 1 / 1;
            padding: 0px 7px;
            margin-right: calc(var(--gutter) / 2);
          }
          &.hasSubmenu > a,
          &.active .submenu {
            padding-right: var(--gutter);
            border-radius: calc(var(--gutter) / 3);
            transition: all 0.2s ease;
          }
          .submenu {
            overflow: hidden;
          }
          &.hasSubmenu.active {
            grid-template-rows: 1fr auto;
          }
          &.hasSubmenu.active > a {
            padding: calc(var(--gutter) / 4) 0;
          }
        }
      }
      button {
        margin: 0;
        border: none;
        background-color: transparent;
        padding: 0px;
        font-size: inherit;
        height: auto;
      }
      .bottomEntries {
        position: sticky;
        padding: var(--gutter);
        padding-top: calc(var(--gutter) / 2);
        z-index: 1;
        bottom: 0;
        > li {
          cursor: pointer;
          a {
            border-radius: calc(var(--gutter) / 3);
            width: max-content;
          }
        }

        .bottomEntriesDropdown {
          position: absolute;
          left: var(--gutter);
          bottom: calc(var(--bottomEntriesBtnHeight));
          width: max-content;
          min-width: calc(var(--menuSize) - var(--gutter) * 3);
          display: inline-block;
          z-index: 2;
          display: grid;
          grid-template-rows: 0fr;
          ol {
            overflow: hidden;
            border-radius: calc(var(--gutter) / 3);
            -webkit-box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
            margin-bottom: 0;
            li {
              padding-right: var(--gutter);
              padding-left: calc(var(--gutter) / 1);
              margin: calc(var(--gutter) / 1) 0;
              padding: 5px 10px !important;
            }
            i,
            .external {
              margin-left: 5px;
            }
          }
        }

        &.open .bottomEntriesDropdown {
          grid-template-rows: 1fr;
        }
        &.isCollapsed {
          padding-right: 0;
          .icon-wrapper {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.mobileMainMenu {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 999;
  display: flex;
  flex-direction: column;

  * {
    box-sizing: border-box;
  }
  &.open {
    transform: translateX(0px);
  }
  &.closed {
    transform: translateX(-100%);
  }
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  height: 100dvh;
  max-height: 100vh;
  max-height: 100dvh;
  width: 100vw;
  overflow-y: auto;
  .header {
    padding: calc(var(--gutter) * 2);
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  button.close {
    padding: 5px;
    font-size: $font-size-xl;
    margin-left: auto;
  }
  .entries {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calc(var(--gutter) * 2);
    flex-grow: 1;
    hr.invisible {
      display: none;
    }
    ol {
      display: grid;
      grid-template-columns: repeat(3, minmax(80px, 1fr));
      width: 100%;
      margin-bottom: calc(var(--gutter) / 1.5);
      li {
        white-space: nowrap;
        padding: calc(var(--gutter) / 3);
        text-align: left;
        &.quickstart {
          display: none;
        }
        font-size: 15px;
        a {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &:hover {
            text-decoration: none;
          }
          &:not(.toggleBottomEntriesBtn) .icon-wrapper {
            border-radius: calc(var(--gutter) / 2);
            display: grid;
            place-items: center;
            aspect-ratio: 11 / 8;
            width: 100%;
          }
        }
        span {
          padding: calc(var(--gutter) / 3);
          max-width: 100%;
        }
        i {
          font-size: 24px;
        }
        ol {
          padding-top: calc(var(--gutter) / 3);
          li {
            padding-left: 0;
          }
        }
      }
    }
  }
  ol {
    &:has(+ .bottomEntries.open) {
      display: none;
    }
    &.bottomEntries {
      display: block;
      max-width: 100%;
      cursor: pointer;
      > li a i {
        aspect-ratio: unset;
        width: 28px;
        height: 28px;
        margin-right: var(--gutter);
      }
      ol {
        overflow: hidden;
        grid-template-columns: 1fr;
        gap: var(--gutter);
        li {
          display: flex;
          padding: calc(var(--gutter) / 2) var(--gutter);
          border-radius: $border-radius;
          a {
            width: 100%;
            text-align: center;
            padding: calc(var(--gutter) * 2) var(--gutter);
          }
          i {
            display: none;
          }
        }
      }
      .toggleBottomEntriesBtn {
        flex-direction: row;
        justify-content: flex-start;
        border-radius: calc(var(--gutter) / 3);
      }
      .bottomEntriesDropdown {
        padding-bottom: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% + var(--gutter) * 4);
        z-index: 1;
        display: none;
      }
      &.open {
        // > li {
        //   display: none;
        // }
        .bottomEntriesDropdown {
          padding: calc(var(--gutter) * 2);
          padding-bottom: calc(var(--gutter) * 3);
          max-width: 100vw;
          box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.2);
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .icon-wrapper {
            aspect-ratio: unset;
          }
        }
      }
    }
  }
}
