#playlists {
  & > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .playlist {
    border-radius: $border-radius;
    margin-bottom: var(--gutter);
    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @extend .contentHeader;
      button {
        margin-left: calc(var(--gutter) / 2);
        svg {
          margin-right: calc(var(--gutter) / 4);
        }
      }
    }
    .entries {
      display: grid;
      grid-template-columns: repeat(2, minmax(150px, 1fr));
      @include respondTo(md) {
        grid-template-columns: repeat(4, minmax(100px, 1fr));
      }
      @include respondTo(lg) {
        grid-template-columns: repeat(6, minmax(100px, 1fr));
      }
      grid-gap: var(--gutter);
    }
  }
}

#playlistEdit {
  #header {
    display: flex;
    justify-content: space-between;
  }
  #playlistContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: var(--gutter);
    .fields,
    #playlistForm {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
    .entries {
      display: grid;
      grid-template-columns: 1fr;
      @include respondToMax(lg) {
        grid-template-columns: 1fr 1fr;
      }
      .entry {
        &.dragged {
          border: 2px solid $cg-black;
        }
        .comments {
          padding: 15px 0;
        }
        &:hover {
          cursor: grab;
        }
        display: grid;
        grid-template-columns: 0.1fr 0.2fr 1.5fr 1fr 0.5fr;
        grid-gap: var(--gutter);
        p {
          padding: calc(var(--gutter) / 2);
        }
        align-items: center;
        img {
          width: 100%;
        }
      }
    }
  }
}
