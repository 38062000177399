.twoColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--gutter);
}
.threeColumns {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: var(--gutter);
}
