#app > #content > #notice {
  max-height: 60px;
  padding: calc(var(--gutter) / 4);
  display: flex;
  justify-content: center;
  background-color: $cg-red;
  align-items: center;
  p {
    margin: 0;
    text-align: center;
  }
  svg,
  p,
  a,
  span {
    color: black;
    margin: 0 calc(var(--gutter) / 4);
  }
}
