.notifications-wrapper {
  .notifications-tr {
    .notification {
      &.notification-success {
        border-top: 2px solid $cg-green !important;
        .notification-dismiss {
          background-color: $cg-green !important;
        }
      }
      &.notification-error {
        border-top: 2px solid $cg-red !important;
        .notification-dismiss {
          background-color: $cg-red !important;
        }
      }
      background-color: $cg-grey-lightest !important;
      color: $cg-black !important;
      font-weight: bold !important;
      max-height: 200px !important;
    }
  }
}
