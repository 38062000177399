#navbarContainer {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5;
  display: flex;
  height: var(--navbarHeight);
  #navbar {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--gutter) / 1);
    & > .left {
      .burger {
        font-size: $font-size-lg;
      }
      display: flex;
      h1 {
        margin: 0;
        font-size: $font-size-lg;
      }
      .selector,
      h1 {
        min-width: 250px;
        margin-left: 27px;
        @include lessThan(lg) {
          min-width: 50px;
        }
      }
    }
    & > .right {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      h2 {
        color: $cg-red;
      }
      .mainSearch {
        min-width: 380px;
        .container {
          button {
            font-size: $text-md !important;
            padding: 0;
          }
        }
      }
      & > li {
        display: inline;
        padding: 0 10px;
        & button.reset {
          padding: 7px 5px;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: 1px;
          font-size: $font-size-xs;
          color: inherit;
          vertical-align: baseline;
        }
      }
      .shortcuts {
        position: relative;
        button.reset {
          padding: 5px;
          line-height: 1;
          height: 36px;
          border-radius: calc(var(--gutter) / 2);

          i {
            font-size: 20px;
          }
        }
        .shortcutsContainer {
          border-radius: $border-radius;
          min-width: 250px;
          position: absolute;
          top: calc(var(--gutter) * 3);
          // half container size + button size + padding
          left: -125px + 10px + 18px;
          overflow: hidden;
          .entry {
            padding: calc(var(--gutter) * 2);
            font-size: $text-lg;
            display: flex;
            flex-direction: column;
            align-items: center;
            i {
              margin-bottom: var(--gutter);
            }
          }
        }
      }
    }
  }
}

#mobileNavbarContainer {
  position: fixed;
  top: 0;
  width: 100vw;
  height: auto;
  z-index: 9;
  #mobileNavbar {
    padding: calc(var(--gutter) / 1);
    display: flex;
    flex-direction: column;
    ul#mobileNavbarList {
      i[class^="cg-icon-"] {
        font-size: $text-xl;
      }
      height: 38px;
      display: flex;
      align-items: center;
      display: flex;
      justify-content: space-between;
      li {
        padding: 0 calc(var(--gutter) / 2);
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.shortcuts {
          position: relative;
          .shortcutsContainer {
            border-radius: $border-radius;
            min-width: 60vw;
            position: absolute;
            top: calc(var(--gutter) * 5);
            left: -20px;
            overflow: hidden;
            .entry {
              i {
                margin-bottom: var(--gutter);
              }
              padding: calc(var(--gutter) * 2);
              font-size: $text-lg;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        }
      }
    }
  }
  .searchAndSelect {
    padding: calc(var(--gutter) / 1) 0;
    display: flex;
    justify-content: space-between;
    #navbarSearch {
      width: 100%;
      .select__control {
        border: none;
      }
    }
  }
}

// profile section
nav#mobileNavbar,
nav#navbar {
  .circled {
    width: 35px;
    height: 35px;
    border: 1px dotted grey;
    border-radius: 50%;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.reset {
    margin: 0;
    border: none;
    background-color: transparent;
    color: inherit;
  }
  &#mobileNavbar {
    .circled {
      width: 35px;
      height: 35px;
    }
    .profile {
      .dropdown {
        top: calc(100% + var(--gutter));
        left: 0px;
        min-width: 300px;
      }
    }
  }
  &#navbar {
    .profile {
      .dropdown {
        top: calc(100% + var(--gutter));
        left: -270px;
        min-width: 300px;
      }
    }
  }
  .profile {
    position: relative;
    .dropdown {
      z-index: 99;
      position: absolute;
      font-weight: bold;
      border-radius: 15px;
      overflow: hidden;
      button {
        margin: 0;
      }
      p {
        margin-bottom: 5px;
      }
      .header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
          font-size: $text-md;
          margin: 0;
          margin-left: var(--gutter);
        }
      }
      .appearance {
        display: flex;
        justify-content: space-between;
        .themeSelector {
          button {
            margin: 0;
            border: none;
            background-color: transparent;
            font-weight: normal;
            &.active {
              font-weight: bold;
            }
          }
        }
      }
      .quickstart {
        padding: 0;
      }
      a,
      p,
      button {
        font-weight: bold;
      }
      .version {
        display: flex;
        justify-content: space-between;
      }
      button.logout {
        margin: 0;
        border: none;
        background-color: transparent;
        padding: 0;
      }
    }
  }
}
