#inventory {
  #header {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr;
    grid-gap: var(--gutter);
    @include respondTo(lg) {
      align-items: center;
      grid-template-columns: 2fr 1fr;
    }
    #headerLeft {
      display: flex;
      & > * {
        margin-right: calc(var(--gutter) / 1);
      }
      #bulkImportButton {
        display: none;
        @include moreThan(lg) {
          display: block;
        }
      }
      #discogsInventoryButton {
        display: none;
        @include moreThan(md) {
          display: block;
        }
      }
    }
    #headerButtons {
      display: flex;
      flex-direction: row;
      align-items: center;
      button {
        margin: 0;
        margin-right: calc(var(--gutter) / 1);
      }
      @include moreThan(lg) {
        justify-content: flex-end;
        button {
          margin: 0;
          margin-left: var(--gutter);
        }
      }
    }
  }
  .pagination {
    .top {
      display: grid;
      align-items: center;
      grid-gap: calc(var(--gutter) / 2);
      margin-bottom: calc(var(--gutter) / 2);
      @include for-tablet-landscape-up {
        display: flex;
        justify-content: space-between;
      }
      .info {
        color: $cg-blue;
      }
      .left {
        color: $cg-grey;
        & > * {
          margin-right: 10px;
        }
        display: flex;
      }
      .middle {
        display: flex;
        align-items: center;
        button {
          margin-left: var(--gutter);
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        .pagesRecap {
          color: $cg-grey;
          margin-right: var(--gutter);
        }
        ul {
          display: flex;
          li {
            padding: 0 5px;
          }
        }
        .disabled {
          cursor: default;
          color: $cg-grey;
        }
      }
    }
    .bottom {
    }
  }
  #inventoryFilters {
    display: grid;
    grid-gap: var(--gutter);
    margin-bottom: 20px;

    @include moreThan(md) {
      grid-template-columns: 1fr 2fr 1fr 1fr;
      grid-auto-flow: column;
      grid-auto-columns: auto;
    }
    @include lessThan(md) {
      grid-template-columns: 1fr;
    }

    .column {
      display: flex;
      flex-direction: column;
      & > *,
      .from {
        margin-bottom: var(--gutter);
      }
      .from,
      .to {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        button {
          margin-top: 5px;
          font-size: $text-lg;
        }
      }
    }
    p {
      font-weight: bold;
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
  #inventoryTable {
    padding: 0;
    .noEntries {
      padding: 3em;
      display: flex;
      justify-content: center;
      align-items: center;
      .placeholder {
        max-width: 400px;
        margin: auto;
        text-align: center;
        a {
          color: $cg-blue;
          font-weight: bold;
        }
      }
    }
    .inventoryEntry {
      // display: grid;
      // .itemTitle {
      //   width: 100%;
      //   display: flex;
      //   justify-content: space-between;
      //   p.entryTitle {
      //     font-weight: 600;
      //   }
      // }
      // p.entryTitle {
      //   margin-top: 0;
      //   font-weight: bold;
      // }
      // p.prices {
      //   margin: 0;
      // }
      // .listingEntry {
      //   width: 100%;
      // }
    }
  }
}

.listingHistory {
  .modalHeader {
    padding: var(--gutter);
  }
}

#inventoryImport {
  padding: var(--gutter);
  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin: 0;
    }
    #left,
    #right {
      button {
        margin-left: calc(var(--gutter) / 2);
      }
      display: flex;
    }
  }
  #content {
    #notice {
      input[type="file"] {
        display: none;
      }
      label {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 6px 12px;
        cursor: pointer;
      }
      text-align: center;
    }
    #importTable {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: calc(var(--gutter) * 2);
      .state-0 {
        background-color: $cg-red;
        border-color: $cg-red;
      }
      .state-1 {
        background-color: $cg-orange;
        border-color: $cg-orange;
      }
      .state-2 {
        background-color: $cg-green;
        border-color: $cg-green;
      }
      .entry {
        border-width: 1px;
        border-style: dashed;
        .listingEntry {
          .header {
            display: flex;
            align-items: center;
            img {
              max-width: 50px;
              margin-right: var(--gutter);
            }
            .submitted {
              color: $cg-green;
            }
            .error {
              color: $cg-red;
            }
          }
        }
        .footer {
          p {
            margin: 0;
            padding: 5px;
          }
          display: flex;
          justify-content: space-between;
        }
        .row {
          display: grid;
          grid-template-columns: 1fr;
          grid-column-gap: calc(var(--gutter) / 2);
          border: 1px solid $cg-grey-lightest;
          padding: var(--gutter);
          div[class^="state-"] {
            padding: 5px;
            display: flex;
            align-items: center;
          }
          .release,
          .listing {
            .fields {
              display: grid;
              grid-gap: calc(var(--gutter) / 4);
              grid-template-columns: 1fr; //repeat(auto-fit, minmax(50px, 1fr));
            }
            padding: calc(var(--gutter) / 2) 0;
            h2,
            h3 {
              margin: 0;
            }
            label {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}

#bulkInventoryModal {
  & > * {
    margin-bottom: calc(var(--gutter));
  }

  & > .header {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
    }
    h2 {
      font-weight: normal;
    }
  }

  & > .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
    }
  }

  & .content {
    .bulkInventoryFields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        "categories categories"
        "stock price"
        "status location"
        "secondHand supplierCode"
        "available preOrder";
      .field {
        padding: calc(var(--gutter) / 2);
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: calc(var(--gutter) / 2);
        .labelHeader {
          display: flex;
          justify-content: space-between;
          font-weight: bold;
          .updateType {
            .cg-radio {
              flex-direction: row;
              label {
                margin: 0;
                margin-right: var(--gutter);
              }
            }
          }
          button {
            padding: 0;
          }
          &:not(.active) {
            font-weight: normal;
          }
        }
        .fieldContent {
          display: grid;
          .findAndReplace {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: var(--gutter);
          }
        }
      }
    }
  }
}
