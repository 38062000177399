.dashboardBlock {
  padding: var(--gutter);
  border-radius: $border-radius;
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: var(--gutter);
    h3,
    p {
      margin: 0;
    }
  }
  .empty {
    margin-top: 50px;
    text-align: center;
    p {
      margin: 0;
    }
  }
  canvas {
    height: 100% !important;
    width: 100% !important;
  }
}

#dashboard {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas:
    "globals visits"
    "recentOrders recentOrders"
    "salesOverTime salesOverTime"
    "pageViews pageViews"
    "newMessages newMessages"
    "origins origins"
    "salesLocations salesLocations"
    "news news"
    "bestSellers bestSellers";

  @include moreThan(sm) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas:
      "globals globals visits visits"
      "salesOverTime salesOverTime recentOrders recentOrders"
      "pageViews pageViews pageViews pageViews"
      "newMessages newMessages origins origins"
      "salesLocations salesLocations news news"
      "bestSellers bestSellers bestSellers bestSellers";
  }
  @include moreThan(lg) {
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas:
      "salesOverTime salesOverTime salesOverTime recentOrders recentOrders recentOrders globals globals"
      "salesOverTime salesOverTime salesOverTime recentOrders recentOrders recentOrders visits visits"
      "newMessages newMessages newMessages origins origins pageViews pageViews pageViews"
      "bestSellers bestSellers bestSellers salesLocations salesLocations news news news";
  }

  padding-bottom: var(--gutter);

  .cg-capsuleGroup {
    & > div {
      display: flex;
      gap: 0px;
      @include lessThan(md) {
        column-gap: 0px;
      }
    }
    button {
      font-size: $font-size-xs;
      text-transform: uppercase;
      border-radius: 17.5px;
      padding: 4px 10px;
      height: auto;
      font-weight: normal;
      letter-spacing: 1.8px;
      margin: 0;
      margin-left: calc(var(--gutter) / 3);
      margin-bottom: calc(var(--gutter) / 2);
      &:first-child {
        margin-left: calc(var(--gutter) / 2);
      }
      &.active {
        border-color: $cg-blue;
        color: $cg-blue;
      }
      &:hover {
        border-color: $cg-blue;
        color: $cg-blue;
      }
    }
  }

  #newMessages {
    grid-area: newMessages;
    @extend .dashboardBlock;
    padding: 0;
    p {
      margin: 0;
    }
    & > .header {
      padding: var(--gutter);
      margin: 0;
    }
    .entries {
      .entry {
        border: 1px solid transparent;
        cursor: pointer;
        border-radius: 0;
        padding: 5px var(--gutter);
        &:hover {
          cursor: pointer;
          border-color: $cg-blue;
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: $font-size-sm;
          p {
            display: flex;
            align-items: center;
            svg {
              margin-right: 5px;
            }
            font-weight: bold;
          }
          margin-bottom: 2px;
        }
        .content {
          font-size: $font-size-sm;
        }
      }
    }
  }

  #salesOverTime {
    grid-area: salesOverTime;
    @extend .dashboardBlock;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-weight: normal;
      }
      p.date {
        color: $cg-grey;
      }
    }
    .chart {
      height: auto;
    }
  }

  #bestSellers {
    grid-area: bestSellers;
    @extend .dashboardBlock;
    .entries {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-auto-rows: auto;
      grid-gap: calc(var(--gutter) / 2);
    }
  }

  #salesLocations {
    grid-area: salesLocations;
    @extend .dashboardBlock;
    .entries {
      .entry {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  #recentOrders {
    grid-area: recentOrders;
    @extend .dashboardBlock;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & > .content > .header {
      padding: var(--gutter);
      margin: 0;
    }
    .entries {
      .entry {
        border: 1px solid transparent;
        border-radius: 0;
        padding: calc(var(--gutter) / 2) var(--gutter);
        &:hover {
          cursor: pointer;
          border-color: $cg-blue;
        }
        p {
          margin: 0;
        }
        .heading,
        .info {
          display: grid;
          grid-template-columns: 1.5fr 2fr 1fr 1fr;
          span:last-child {
            text-align: right;
          }
        }
        .heading {
          margin-bottom: calc(var(--gutter) / 2);
        }
        .info {
          font-size: $font-size-xs;
        }
      }
    }
    .seeMore {
      text-align: center;
      font-size: $font-size-sm;
      padding-top: calc(var(--gutter) / 2);
      padding-bottom: var(--gutter);
    }
  }

  #origins {
    grid-area: origins;
    @extend .dashboardBlock;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        font-weight: normal;
      }
      p.date {
        color: $cg-grey;
      }
    }
  }

  #visits {
    grid-area: visits;
    @extend .dashboardBlock;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin: 0;
    }
    .header {
      margin-bottom: calc(var(--gutter) * 2);
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      .left {
        h2 {
          text-align: center;
        }
      }
      .right {
        p {
          text-align: right;
        }
      }
    }
    .setup {
      text-align: center;
    }
  }

  #globals {
    grid-area: globals;
    @extend .dashboardBlock;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      h2 {
        font-weight: normal;
        margin: 0;
      }
      p.date {
        color: $cg-grey;
      }
    }
    .bottom {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: calc(var(--gutter) * 2);
      h2 {
        font-weight: normal;
        font-size: $font-size-xl;
      }
      p {
        margin: 0;
      }
      .time {
        color: $cg-grey;
        font-size: $font-size-lg;
      }
    }
  }

  #newsPanel {
    grid-area: news;
    @extend .dashboardBlock;
    padding: 0;
    .header {
      margin-bottom: 0;
      padding: calc(var(--gutter) / 1);
      padding-bottom: calc(var(--gutter) / 2);
    }
    .post {
      padding: calc(var(--gutter) / 2) var(--gutter);
      border-radius: 0;
      display: flex;
      justify-content: space-between;
      h4 {
        margin: 0;
      }
      a,
      p {
        margin: 0;
      }
      a.title {
        font-size: $text-md;
        margin: 0;
      }
      p.date {
        .new {
          padding: calc(var(--gutter) / 3);
          font-size: calc($text-md - 2px);
          color: $cg-green;
          background-color: #173f35;
          border-radius: calc(var(--gutter) / 3);
          margin-right: 10px;
        }
        color: $cg-grey;
        font-size: $text-md;
      }
    }
  }

  #notificationPanel {
    grid-area: notifications;
    @extend .dashboardBlock;
  }

  #originChart {
    grid-area: origins;
    @extend .dashboardBlock;
    padding: calc(var(--gutter) / 2) 0;
  }

  #pageViews {
    grid-area: pageViews;
    min-height: 200px;
    @extend .dashboardBlock;
    padding: var(--gutter);
    .configureAnalytics {
      padding: calc(var(--gutter) * 2);
      text-align: center;
      font-size: $font-size-lg;
    }
  }
}
