@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.rotate {
  -webkit-animation: rotation 1s infinite linear;
}
