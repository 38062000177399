$gutter: 10px;

#newsArticles {
  padding: 0;
  margin: 0;
  .content {
    padding: $gutter * 2;
    .article {
      @include moreThan(lg) {
        max-width: 40vw;
      }
      .campaignHtml {
        margin: 0 auto;
        margin-bottom: $gutter * 6;
      }
      margin: $gutter * 6 auto;
      box-shadow: 1px 1px 10px 0 rgba(132, 132, 132, 0.5);
      padding: $gutter * 3;
      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $gutter * 6;
        h2 {
          font-size: $font-size-lg;
          font-weight: normal;
          margin: 0;
          line-height: 1.2;
        }
        .date {
          font-size: $font-size-lg;
          margin: 0;
          color: #a4a4a4;
          line-height: 1.2;
        }
      }
      .banner {
        margin-bottom: $gutter * 2;
      }
      .content {
        [class^="intercom-"],
        pre,
        ul,
        p {
          margin-bottom: $gutter * 2;
        }
        b {
          font-weight: bold;
          font-size: $font-size-lg;
          margin-bottom: var(--gutter);
        }
        p,
        li,
        span {
          font-size: $font-size-lg;
        }
        ul {
          list-style-type: disc;
          padding-left: calc(var(--gutter) * 2);
        }
        code {
          padding: var(--gutter);
          border-radius: 10px;
          font-style: italic;
          font-size: $font-size-lg;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        margin-top: $gutter * 2;
        h2 {
          font-size: $font-size-lg;
          font-weight: normal;
          margin: 0;
        }
        .tags {
          span {
            padding: 5px 10px;
            text-transform: uppercase;
            background-color: #d4efe7;
            color: #009669;
            font-weight: 500;
            border-radius: 17.5px;
            margin-left: var(--gutter);
          }
        }
      }
    }
  }
}
