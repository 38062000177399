.marketSuggestions {
  button {
    font-size: $text-lg;
  }
}

.marketSuggestionsModal {
  .content {
    .discogsSuggestions {
      margin-bottom: calc(var(--gutter) * 2);
      .suggestions {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(4, 1fr);
        button {
          font-size: $text-sm;
          padding: 5px 0px;
        }
      }
    }
    .dicsogsScrappedPrices {
      .header {
        margin: 0;
      }
      .statistics {
        margin-bottom: calc(var(--gutter) * 1);

        display: grid;
        grid-template-columns: 1fr 1fr;
        p {
          margin: 0;
        }
      }
      .priceTable {
        .entry {
          border: 1px solid transparent;
          &:hover {
            cursor: pointer;
            border-color: $cg-blue;
          }
          display: grid;
          grid-gap: var(--gutter);
          grid-template-columns: 1.5fr 1fr 0.5fr;
          &.active {
            font-weight: bold;
          }
          p {
            margin: 0;
          }
          .price {
            text-align: center;
          }
          .seller,
          .price {
          }
        }
      }
    }
  }
}
