#collections {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) * 2);
  }
  .intro {
    max-width: 30vw;
    margin: 100px auto;
    & > .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }
  h1,
  h2,
  p {
    margin: 0;
  }
  .entries {
    .entry {
      border: 1px solid transparent;
      &:hover {
        cursor: pointer;
        border: 1px solid $cg-blue;
      }
      display: grid;
      grid-template-columns: 1fr 0.5fr 1fr;
      @include lessThan(md) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

#collection {
  .header {
    display: flex;
    justify-content: space-between;
    @include lessThan(md) {
      flex-direction: column;
    }
    margin-bottom: calc(var(--gutter) * 2);
    .left,
    .right {
      display: flex;
      h1 {
        margin-bottom: 0;
      }
    }
    .left {
      button {
        padding: 5px;
      }
    }
    button {
      margin-left: var(--gutter);
    }
  }
  .content {
    margin-bottom: calc(var(--gutter) * 2);
    .details {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 2fr;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
      margin-bottom: var(--gutter);
      .title {
        & > * {
          margin-bottom: var(--gutter);
        }
        .sort {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: var(--gutter);
        }
      }
      .quillContainer {
        label.quillLabel {
          font-style: normal;
          font-weight: 600;
          font-size: inherit;
          line-height: 1;
        }
        .quillEditor {
          margin-top: 10px;
        }
        .quill {
          .ql-container {
            min-height: 100px;
          }
        }
      }
    }
    .filtersAndPreview {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 2fr;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
      .filters {
        & > * {
          margin-bottom: var(--gutter);
        }
        .filter {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }
      }
    }
  }
  .preview {
    .description {
      text-align: center;
      & > * {
        margin: calc(var(--gutter) * 2);
      }
    }
    .items {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: repeat(4, 1fr);
      .item {
        display: flex;
        flex-direction: column;
        & > * {
          margin-bottom: var(--gutter);
        }
        img {
          width: 100%;
        }
      }
    }
  }
}

.newCollectionModal {
  .content {
    form {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
  }
}
