.stickers {
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    margin-bottom: calc(var(--gutter) * 2);
  }
  & > .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    grid-gap: calc(var(--gutter) * 4);
    h2 {
      font-size: $text-md;
    }
    p,
    h2 {
      margin: 0;
    }
    .left {
      .stickersv2 {
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: calc(var(--gutter) * 2);
          .left {
            display: flex;
            align-items: center;
            column-gap: var(--gutter);
          }
        }
        .clear {
          margin-top: var(--gutter);
        }
        .content {
          .entries {
            margin-top: calc(var(--gutter) * 2);
            display: grid;
            grid-gap: var(--gutter);
          }
        }
      }
    }
    .right {
      & > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: calc(var(--gutter) * 2);
      }
      & > * {
        margin-bottom: calc(var(--gutter) * 2);
      }
      .loader {
        margin: 0 calc(var(--gutter) / 2);
      }
      .fields {
        margin-top: 1em;
        input {
          margin-bottom: 1em;
        }
        & div {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .stickerEntry {
      border: 1px solid transparent;
      border-radius: $border-radius;

      display: grid;
      grid-gap: 5px;
      .top {
        display: flex;
        justify-content: space-between;
        .main {
          font-weight: bold;
        }
        & > p > .price {
          margin-right: 15px;
        }
      }
      .bottom {
        padding: 5px var(--gutter);
        font-size: $text-sm;
        display: flex;
        justify-content: space-between;
        @include lessThan(md) {
          flex-direction: column;
        }
        .line > * {
          margin-right: 15px;
        }
      }
    }
  }
}
