.quillEditor {
  .ql-container {
    height: 150px;
  }
  .ql-toolbar {
    color: inherit;
  }
  .ql-editor {
    em {
      font-style: italic;
    }
  }
  .quillInstance {
    height: auto;
    .ql-editor.ql-blank::before {
      color: inherit;
    }
  }

  .ql-toolbar .ql-fill {
    fill: $cg-grey;
    stroke: none;
  }
  .ql-toolbar .ql-picker {
    color: $cg-grey;
  }

  button:hover .ql-stroke,
  .ql-picker-label:hover .ql-stroke {
    fill: none;
    stroke: $cg-purple !important;
  }

  .ql-picker-label.ql-active {
    color: $cg-purple !important;
  }

  .ql-picker-label:hover {
    color: $cg-purple !important;
  }

  .ql-picker-item:hover {
    color: $cg-purple !important;
  }

  .ql-active .ql-stroke {
    fill: none;
    stroke: $cg-purple !important;
  }

  button:hover .ql-fill,
  .ql-picker-label:hover .ql-fill {
    fill: $cg-purple !important;
    stroke: none;
  }

  .ql-active .ql-fill {
    fill: $cg-purple !important;
    stroke: none;
  }
}

.theme-dark,
#modal-dark {
  .quillEditor {
    .ql-toolbar .ql-stroke {
      fill: none;
      stroke: $cg-grey-lighter;
    }

    .ql-toolbar {
      color: inherit;
      border: 1px solid $cg-grey !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .ql-container {
      border: 1px solid $cg-grey !important;
      border-top: 0px !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .quillInstance {
      height: auto;
      .ql-editor.ql-blank::before {
        color: inherit;
      }
    }
  }
}

.theme-light,
#modal-dark {
  .quillEditor {
    .ql-toolbar {
      color: inherit;
      border: 1px solid $cg-grey-lighter !important;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .ql-container {
      border: 1px solid $cg-grey-lighter !important;
      border-top: 0px !important;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .quillInstance {
      height: auto;
      .ql-editor.ql-blank::before {
        color: inherit;
      }
    }
  }
}
