#catalogue {
  padding: var(--gutter);
  #header {
    @extend .contentHeader;
    display: flex;
    flex-direction: row;
    align-items: center;
    .pagination {
      padding: 0;
      margin: 0;
    }
    .paginationWords {
      display: none;
      @include moreThan(lg) {
        display: inline;
      }
    }
    justify-content: space-between;
    @include moreThan(lg) {
      flex-direction: row;
      align-items: center;
    }
  }
  #filtersAndContent {
    display: grid;
    grid-template-columns: 1fr;
    @include moreThan(lg) {
      grid-template-columns: 3fr;
    }
    grid-column-gap: var(--gutter);
    .loader {
      align-self: center;
      text-align: center;
    }
    #catalogueEntries {
      display: grid;
      grid-gap: var(--gutter);
      justify-content: space-between;
      grid-template-columns: repeat(2, 1fr);
      @include moreThan(md) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include moreThan(lg) {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
}
