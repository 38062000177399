#intro {
  &.dark {
    background-color: $cg-grey-darkest;
  }
  &.light {
    background-color: $cg-grey-lightest;
  }
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin-top: -80px;
    max-width: 40vw;
    @include respondTo(lg) {
      max-width: 20vw;
    }
  }
}
