#editor {
  .twoColumns {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1fr;
    @include lessThan(lg) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  hr {
    margin-bottom: calc(var(--gutter) * 1);
  }
  .cg-checkbox {
    margin-bottom: var(--gutter);
  }
}

#editorMenu {
  position: sticky;
  top: var(--navbarHeight);
  height: calc(100vh - var(--navbarHeight));
  padding: 0;
  overscroll-behavior: contain;
  @include for-phone-only() {
    top: var(--navbarHeight);
    margin-top: 0;
  }
  @include lessThan(lg) {
    width: 50px;
  }
  & > .content {
    padding: $cg-spacing-md;
    box-sizing: border-box;
    height: 100%;
    h2 {
      color: $cg-grey;
      font-size: $cg-text-md;
      margin-bottom: 40px;
      @include lessThan(lg) {
        display: none;
      }
    }
    @include lessThan(lg) {
      padding: var(--gutter);
    }
    background-color: $cg-black;
    color: $cg-grey;
    @include lessThan(lg) {
      span {
        display: none;
      }
    }
    ul,
    .entries {
      margin-bottom: var(--gutter);
      li,
      .entry {
        display: flex;
        align-items: flex-end;
        padding: 5px 0;
        font-size: $cg-text-md;
        @include lessThan(lg) {
          font-size: $cg-text-sm;
        }
        a {
          white-space: nowrap;
          &:hover {
            text-decoration: none;
            color: $cg-grey-light;
          }
          i {
            margin-right: 10px;
            font-size: 17px;
          }
        }
      }
      .active {
        color: white;
      }
    }

    .themeMenu {
      .header {
        a:hover {
          text-decoration: none;
        }
        h2 {
          display: flex;
          i {
            margin-right: var(--gutter);
          }
        }
      }
    }
  }
}

.editorContent {
  display: grid;
  grid-template-columns: 180px auto;
  @include lessThan(lg) {
    grid-template-columns: 50px 1fr;
  }
  & > div:nth-child(2) {
    padding: var(--gutter);
  }
  &.collapsed {
    grid-template-columns: 65px auto;
  }
}

#themeBrowser {
  .themePreview {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: calc(var(--gutter) * 3);
      .left {
        display: flex;
        align-items: center;
        & > * {
          margin-right: var(--gutter);
        }
      }
      h2 {
        margin: 0;
      }
      .tags {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: var(--gutter);
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: calc(var(--gutter) * 2);
      .carouselContainer {
        width: 100%;
        .slick-slider {
          overflow: hidden;
          min-width: 100%;
          width: 0;
          .slick-list {
            width: 100%;
          }
          .slick-dots {
            display: flex !important;
            justify-content: center;
            display: inline-block;
            vertical-align: middle;
            margin: var(--gutter) 0 0 0;
            padding: 0;
            text-align: center;
            li {
              &.slick-active {
                button {
                  background-color: $cg-blue;
                }
              }
              button {
                width: 10px;
                height: 10px;
                border: none;
                background: $cg-grey;
                color: transparent;
                cursor: pointer;
                display: block;
                padding: 0;
                border-radius: 20px;
              }
              margin: 0 5px;
            }
          }
        }
      }

      .themeImage {
        width: 100%;
      }

      .text {
        width: 60%;
        @include lessThan(lg) {
          width: 100%;
        }
        margin: 0 auto;
        margin-right: auto;
        p,
        span {
          font-size: $font-size-lg;
          margin: 0;
        }
        p {
          margin-bottom: calc(var(--gutter) * 2);
        }
      }
    }
  }

  .browser {
    .header {
      display: flex;
      margin-bottom: calc(var(--gutter) * 3);

      h2 {
        margin: 0;
      }
      .tags {
        display: flex;
        justify-content: center;
        align-items: center;
        & > * {
          text-transform: capitalize;
          margin: 0 calc(var(--gutter) / 2);
        }
      }
    }
    .themes {
      display: grid;
      justify-items: center;
      grid-gap: calc(var(--gutter) * 2);

      grid-template-columns: repeat(1, 1fr);
      @include moreThan(md) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include moreThan(lg) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include moreThan(xl) {
        grid-template-columns: repeat(4, 1fr);
      }
      .theme {
        .themeImage:hover {
          box-shadow: 3px 3px 5px 0px rgba(181, 181, 181, 0.29);
          -webkit-box-shadow: 3px 3px 5px 0px rgba(181, 181, 181, 0.29);
          -moz-box-shadow: 3px 3px 5px 0px rgba(181, 181, 181, 0.29);
        }
        &:hover {
          cursor: pointer;
        }
        p {
          margin-top: calc(var(--gutter) / 2);
        }
      }
    }
  }
}

.themes {
  position: relative;
  & > .header {
    display: flex;
    justify-content: flex-start;
    button {
      margin-left: var(--gutter);
    }
    margin-bottom: var(--gutter);
  }
  & > .content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include lessThan(lg) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include lessThan(md) {
      grid-template-columns: repeat(1, 1fr);
    }
    grid-gap: var(--gutter);
    .theme {
      padding: var(--gutter);
      border: 2px solid transparent;
      &.isBeingPublished {
        border: 1px dashed $cg-blue;
      }
      &.active,
      &.new:hover {
        border: 2px solid $cg-blue;
      }
      &:hover {
        box-shadow: 3px 3px 5px 0px rgba(181, 181, 181, 0.29);
        -webkit-box-shadow: 3px 3px 5px 0px rgba(181, 181, 181, 0.29);
        -moz-box-shadow: 3px 3px 5px 0px rgba(181, 181, 181, 0.29);
      }
      &.new {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > .header {
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
        & > .content {
          button {
            width: 100%;
            height: auto;
            svg {
              width: 20%;
              height: auto;
            }
          }
          display: flex;
          justify-content: center;
        }
      }
      .content {
        padding: var(--gutter);
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: center;
        h2 {
          font-size: $cg-text-lg;
        }
      }
      .actions {
        padding: var(--gutter);
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          border: none;
          background-color: transparent;
          padding: 0;
          margin: 0;
          font-size: $font-size-md;
        }
      }
    }
  }
}

#newThemeModal {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    label {
      input {
        margin-top: 5px;
      }
      display: flex;
      flex-direction: column;
    }
    button {
      margin-top: 15px;
    }
  }
}

#themeDuplicateModal {
  form {
    & > * {
      margin-bottom: var(--gutter);
    }
  }
}

.menuEditor {
  .header {
    &,
    & > .left {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    a {
      margin-right: var(--gutter);
    }
  }
  & > .contentHeader {
    display: flex;
    justify-content: flex-start;
    & > * {
      margin-right: var(--gutter);
    }
  }
  & > .content {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1.5fr;
    padding: calc(var(--gutter) * 2) 0;
    .left,
    .right {
      h2 {
        text-transform: uppercase;
        letter-spacing: 1.67px;
        font-size: $cg-text-md;
        font-weight: normal;
        font-size: $cg-text-xs;
      }
    }
    .left {
      .stickyElements {
        position: -webkit-sticky;
        position: sticky;
        top: calc(var(--gutter) + var(--navbarHeight));
        .element {
          h3,
          p {
            margin: 0;
          }
          &.dragged {
            // background-color: white;
            border-color: $cg-blue;
          }
          padding: 10px;
          border: 1px solid $cg-grey;
          display: flex;
          margin-bottom: 5px;
          justify-content: space-between;
          border: 1px dashed $cg-grey;
          &.group {
            flex-direction: column;
          }
          .entry {
            display: flex;
            width: 100%;
          }
        }
        .presets {
          margin-top: calc(2 * var(--gutter));
          .link {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .right {
      & > .entries {
        position: sticky;
        top: calc(var(--gutter) + var(--navbarHeight));
        border-radius: $border-radius;
        .entry {
          margin-bottom: 15px;
          border: 1px solid $cg-grey;
          p {
            margin: 0;
          }
          border-radius: $border-radius;
          button.edit {
            display: flex;
            align-items: center;
            border: none;
            padding: 0 5px;
            margin: 0;
            background-color: transparent;
            width: 35px;
            height: 15px;
            svg {
              width: 15px;
              height: 15px;
            }
          }
          .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          &.dropdown {
            .bottom {
              padding: 10px;
              grid-gap: calc(var(--gutter) / 2);
            }
          }
          h3 {
            margin: 0;
            margin-right: calc(var(--gutter));
          }
          .left {
            display: flex;
            align-items: center;
            button {
              padding: 0;
              padding-right: 10px;
              &:hover {
                svg {
                  color: $cg-black;
                  stroke: $cg-black;
                }
              }
              svg {
                color: $cg-grey;
                width: 12px;
                height: 12px;
                stroke: $cg-grey;
              }
            }
          }
        }
        .link,
        .search,
        .homeOrLogin,
        .html,
        .group,
        .image,
        .dropdown,
        .languageSelector,
        .cookieConsent,
        .socialNetworks {
          padding: calc(var(--gutter) / 2);
          margin: calc(var(--gutter) / 4) 0;
        }
        .link {
          .top {
            small {
              margin-right: 10px;
            }
          }
          .bottom {
            display: flex;
            justify-content: space-between;
            label {
              display: flex;
              flex-direction: column;
              input {
                margin-top: 5px;
              }
            }
          }
        }
        .group {
          & > .entries {
            .entry {
              border-radius: $border-radius;
              // border: 1px solid $cg-grey;
            }
          }
        }
        .image {
          display: block;
          .top {
            display: flex;
            justify-content: space-between;
            .left,
            .right {
              display: flex;
            }
            img {
              width: 20px;
            }
          }
          .bottom {
            display: flex;
            flex-direction: column;
            .imageLink {
              label {
                display: flex;
                flex-direction: column;
              }
              margin-top: var(--gutter);
            }
          }
        }
        .socialNetworks {
          .left {
            a {
              i {
                margin-right: var(--gutter);
              }
            }
          }
        }
        .dropzone {
          padding: 15px;
          margin: 5px 0;
          border: 1px dashed $cg-grey-lighter;
          text-align: center;
          height: 15px;
          p {
            text-align: center;
            font-size: $cg-text-xs;
            margin: 0;
            color: $cg-grey;
            font-style: italic;
          }
        }
        .dropdown {
          & > .bottom > .entries > h3 {
            margin: calc(var(--gutter) / 2) 0;
          }
        }
      }
    }
  }
  .draggable {
    &:hover {
      cursor: move;
    }
  }
  .dropzone,
  .draggable {
    border-radius: $border-radius;
  }
}

// icons and logs
.designEditor {
  .header {
    margin-bottom: var(--gutter);
    display: flex;
    justify-content: space-between;
  }
  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include lessThan(lg) {
      grid-template-columns: 1fr;
    }
    grid-gap: var(--gutter);
    .mediaSelector {
      margin-top: 5px;
    }
  }
}

.settingsEditor {
  button[type="submit"] {
    margin-top: var(--gutter);
  }
  & > * {
    margin-bottom: var(--gutter);
  }
  .options {
    .entries {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
  }
  .googleAnalytics {
    .header {
      h2 {
        margin: 0;
      }
    }
    .setup,
    .configureAnalytics {
      text-align: center;
      padding-top: var(--gutter);
      h2 {
        margin-bottom: var(--gutter);
      }
    }
  }
  #openingHours {
    margin-top: var(--gutter);
    p,
    h2,
    h3 {
      margin-bottom: calc(var(--gutter) / 2);
    }
    #days {
      display: grid;
      grid-template-columns: repeat(auto-fill, 280px);
      grid-gap: var(--gutter);
      .day {
        .addOpeningHour {
          margin-top: var(--gutter);
        }
        .ranges {
          display: grid;
          grid-row-gap: calc(var(--gutter) / 2);
          .range {
            border: 1px dashed $cg-black;
            padding: calc(var(--gutter) / 2);
            input {
              width: 80%;
            }
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            .react-datepicker-wrapper span {
              display: none;
            }
          }
        }
      }
    }
  }
  .hosting {
    form {
      .metaNote {
        margin-top: var(--gutter);
      }
      .metaTags {
        .metaTag {
          display: grid;
          grid-template-columns: 1fr 0.2fr;
          margin-bottom: calc(var(--gutter) / 2);
        }
      }
      & > * {
        margin-bottom: var(--gutter);
      }
    }
  }
  .maintenanceMode {
    .password {
      margin-top: var(--gutter);
    }
  }
}

.domainsEditor {
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--gutter);
    .left {
      display: flex;
      h1 {
        margin-right: var(--gutter);
        margin-bottom: 0;
      }
    }
  }
  .domains {
    & > .domain {
      margin-bottom: calc(var(--gutter) * 2);
      & > .header {
        margin-bottom: calc(var(--gutter) * 1);
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right {
          p.primary {
            text-transform: uppercase;
          }
          .cg-button {
            margin-left: var(--gutter);
          }
        }
      }
      .summary {
        & > .row {
          display: grid;
          grid-gap: var(--gutter);
          grid-template-columns: 3fr 2fr;
          @include lessThan(md) {
            grid-template-columns: 1fr;
          }
          &.header {
            font-weight: bold;
          }
          .rowRight {
            display: flex;
            justify-content: space-between;
          }
          .connected {
            background-color: $cg-green;
            color: $cg-grey-lightest;
            padding: 3px 7px;
            border-radius: 5px;
          }
        }
      }
      [class*="step-"] {
        & > * {
          margin-bottom: var(--gutter);
        }
        & > .header {
          display: flex;
          justify-content: space-between;
          .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: $text-md;
            .badge {
              margin-right: var(--gutter);
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              border-radius: 50%;
            }
          }
          .right {
            p {
              text-transform: uppercase;
            }
          }
        }
        .content {
          .record {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: var(--gutter);
          }
        }
        &.failed,
        &.active {
          .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        &.failed {
          .header {
            .right {
              p {
                color: $cg-red;
              }
            }
            .badge {
              border: 1px solid $cg-red;
            }
          }
        }
        &.active {
          .header {
            .right {
              p {
                color: $cg-blue;
              }
            }
            .badge {
              border: 1px solid $cg-blue;
            }
          }
        }
        &.complete {
          .header {
            .left {
              .badge {
                background-color: $cg-green;
                color: $cg-grey-lightest;
              }
            }
            .right > p {
              color: $cg-green;
            }
          }
        }
      }
      hr {
        margin-bottom: calc(var(--gutter) * 2) !important;
        margin-top: calc(var(--gutter) * 2) !important;
      }
    }
  }
}

.checkoutSettings {
  #payments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    grid-gap: var(--gutter);
    .preferencesFields {
      flex-direction: column;
      & > * {
        margin-bottom: var(--gutter);
      }
    }
    .titleAndLogo {
      display: flex;
      justify-content: space-between;
      align-content: center;
      a {
        width: 60px;
        img {
          height: auto;
          width: 100%;
        }
      }
    }
    #stripeConnectAndFields {
      display: grid;
      grid-gap: var(--gutter);
      #payfast {
        padding: var(--gutter);
      }
      #stripe {
        padding: var(--gutter);
        #connect {
          a {
            text-align: center;
            margin-top: var(--gutter);
          }
        }
        #connected {
          border: 1px solid $cg-black;
          border-radius: 5px;
          padding: var(--gutter);
        }
        .stripeDisconnect {
          margin-top: var(--gutter);
        }
      }
    }
    #paypal {
      form,
      .preferencesFields {
        margin: var(--gutter) 0;
      }
    }
  }
  .shippingPreferences {
    button[type="submit"] {
      margin-top: var(--gutter);
    }
    .header {
      display: flex;
      justify-content: space-between;
    }
    .shippingOptions {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
  }
}

.checkoutShipping {
  #shippingPolicies {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
    .policy {
      .policyHeader {
        display: flex;
        justify-content: space-between;
        input {
          margin-left: 5px;
        }
      }
      display: grid;
      border-radius: $border-radius;
      grid-template-columns: 1fr 1fr;
      @include respondToMax(lg) {
        grid-template-columns: 1fr;
      }
      grid-gap: var(--gutter);
      .left {
        .tag {
          font-size: $cg-text-sm;
        }
        h2 {
          margin-bottom: var(--gutter);
        }
      }
      .right {
        display: grid;
        .policyFooter {
          justify-self: end;
          button {
            margin-left: var(--gutter);
          }
        }
        .methods {
          .method {
            padding: calc(var(--gutter) / 2);
            @include respondToMax(lg) {
              padding: 0;
            }
            display: grid;
            grid-template-columns: 1fr;
            border: 1px dashed $cg-grey;
            margin-top: var(--gutter);
            .methodHeader {
              display: flex;
              justify-content: space-between;
              @include respondToMax(lg) {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: calc(var(--gutter) / 2);
              }
              button {
                align-self: flex-end;
                margin: 0;
              }
              .select {
                text-transform: capitalize;
                min-width: 120px;
              }
              margin-bottom: calc(var(--gutter) / 2);
            }
            .ranges {
              display: grid;
              grid-column-gap: var(--gutter);
              grid-row-gap: calc(var(--gutter) / 2);
              .range {
                padding: calc(var(--gutter) / 2);
                display: grid;
                small {
                  margin-top: calc(var(--gutter) / 2);
                }
                .rangeEdit {
                  display: grid;
                  grid-template-columns: 1fr 1fr 1fr 1fr;
                  @include respondToMax(lg) {
                    grid-template-columns: 1fr;
                  }
                  grid-column-gap: calc(var(--gutter) / 2);
                }

                grid-column-gap: calc(var(--gutter) / 2);
                border: 1px dashed black;
                button {
                  align-self: flex-end;
                  margin: 0;
                }
              }
            }
          }
          @include respondToMax(lg) {
            button {
              width: 100%;
            }
          }
        }
        label {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

#menus {
  & > .header {
    display: flex;
    justify-content: flex-start;
    button {
      margin-left: var(--gutter);
    }
  }
  & > .entries {
    .entry {
      border: 1px solid transparent;
      &:hover {
        cursor: pointer;
        border: 1px solid $cg-blue;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 0.2fr;
      @include lessThan(lg) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

#addDomainNameModal {
  form {
    margin-bottom: calc(var(--gutter) * 2);
  }
}
