@font-face {
  font-family: "Graphik Web";
  src: url("https://static.common-ground.io/fonts/GraphikWeb/bold/Graphik-Bold-Web.eot");
  src: url("https://static.common-ground.io/fonts/GraphikWeb/bold/Graphik-Bold-Web.eot?#iefix") format("embedded-opentype"),
    url("https://static.common-ground.io/fonts/GraphikWeb/bold/Graphik-Bold-Web.woff2") format("woff2"),
    url("https://static.common-ground.io/fonts/GraphikWeb/bold/Graphik-Bold-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("https://static.common-ground.io/fonts/GraphikWeb/medium/Graphik-Medium-Web.eot");
  src: url("https://static.common-ground.io/fonts/GraphikWeb/medium/Graphik-Medium-Web.eot?#iefix") format("embedded-opentype"),
    url("https://static.common-ground.io/fonts/GraphikWeb/medium/Graphik-Medium-Web.woff2") format("woff2"),
    url("https://static.common-ground.io/fonts/GraphikWeb/medium/Graphik-Medium-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("https://static.common-ground.io/fonts/GraphikWeb/regular/Graphik-Regular-Web.eot");
  src: url("https://static.common-ground.io/fonts/GraphikWeb/regular/Graphik-Regular-Web.eot?#iefix") format("embedded-opentype"),
    url("https://static.common-ground.io/fonts/GraphikWeb/regular/Graphik-Regular-Web.woff2") format("woff2"),
    url("https://static.common-ground.io/fonts/GraphikWeb/regular/Graphik-Regular-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("https://static.common-ground.io/fonts/GraphikWeb/light/Graphik-Light-Web.eot");
  src: url("https://static.common-ground.io/fonts/GraphikWeb/light/Graphik-Light-Web.eot?#iefix") format("embedded-opentype"),
    url("https://static.common-ground.io/fonts/GraphikWeb/light/Graphik-Light-Web.woff2") format("woff2"),
    url("https://static.common-ground.io/fonts/GraphikWeb/light/Graphik-Light-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik Web";
  src: url("https://static.common-ground.io/fonts/GraphikWeb/extralight/Graphik-Extralight-Web.eot");
  src: url("https://static.common-ground.io/fonts/GraphikWeb/extralight/Graphik-Extralight-Web.eot?#iefix") format("embedded-opentype"),
    url("https://static.common-ground.io/fonts/GraphikWeb/extralight/Graphik-Extralight-Web.woff2") format("woff2"),
    url("https://static.common-ground.io/fonts/GraphikWeb/extralight/Graphik-Extralight-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "cg-icons";
  src: url("https://static.common-ground.io/common/fonts/backoffice/cg-font-backoffice-regular.woff2?v=11") format("woff2"),
    url("https://static.common-ground.io/common/fonts/backoffice/cg-font-backoffice-regular.woff?v=11") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Simplon Mono Light";
  src: url("https://static.common-ground.io/common/fonts/docs/SimplonMono/SimplonMono-Light-WebS.eot");
  src: url("https://static.common-ground.io/common/fonts/docs/SimplonMono/SimplonMono-Light-WebS.eot?#iefix") format("embedded-opentype"),
    url("https://static.common-ground.io/common/fonts/docs/SimplonMono/SimplonMono-Light-WebS.woff2") format("woff2"),
    url("https://static.common-ground.io/common/fonts/docs/SimplonMono/SimplonMono-Light-WebS.woff") format("woff");
  font-style: normal;
  font-stretch: normal;
  font-display: block;
}

[class^="cg-icon-"],
[class*=" cg-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "cg-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* // search-mobile	ba00 */
.cg-icon-search-mobile:before {
  content: "\ea00";
}
/* // view-grid-mobile	ba01
// filter-mobile	ba02
// view-list-mobile	ba03
// view-list	ba04
// view-grid	ba05 */
.cg-icon-search:before {
  content: "\ea06";
}
/* // catalogue-down	ba07 */
.cg-icon-filter-less:before {
  content: "\ea08";
}
/* // filter-close	ba09 */
.cg-icon-filter-more:before {
  content: "\ea0A";
}
.cg-icon-burger:before {
  content: "\ea0B";
}
.cg-icon-burger-close:before {
  content: "\ea0c";
}
.cg-icon-play:before {
  content: "\ea0d";
}
.cg-icon-pause:before {
  content: "\ea0e";
}
/* // player-prev	ba0f */
.cg-icon-player-prev:before {
  content: "\ea0f";
}
.cg-icon-player-play:before {
  content: "\ea10";
}
.cg-icon-player-pause:before {
  content: "\ea11";
}
.cg-icon-player-next:before {
  content: "\ea12";
}
/* // basket-cart	ba13
// basket-cart-preorder	ba14
// basket-selected	ba15 */
.cg-icon-basket-add:before {
  content: "\ea16";
}
/* // basket-remove	ba17 */
.cg-icon-wantlist:before {
  content: "\ea18";
}
.cg-icon-wantlist-fill:before {
  content: "\ea19";
}
.cg-icon-articles:before {
  content: "\ea1a";
}
.cg-icon-buttons:before {
  content: "\ea1b";
}
.cg-icon-checkout:before {
  content: "\ea1c";
}
.cg-icon-collection:before {
  content: "\ea1d";
}
.cg-icon-colors:before {
  content: "\ea1e";
}
.cg-icon-credit-notes:before {
  content: "\ea1f";
}
.cg-icon-css:before {
  content: "\ea20";
}
.cg-icon-customers:before {
  content: "\ea21";
}
.cg-icon-dashboard:before {
  content: "\ea22";
}
.cg-icon-developers:before {
  content: "\ea23";
}
.cg-icon-docs:before {
  content: "\ea24";
}
.cg-icon-eshop-editor:before {
  content: "\ea25";
}
.cg-icon-exports:before {
  content: "\ea26";
}
.cg-icon-fonts:before {
  content: "\ea27";
}
.cg-icon-themes:before {
  content: "\ea28";
}
.cg-icon-grids:before {
  content: "\ea29";
}
.cg-icon-guide:before {
  content: "\ea2a";
}
.cg-icon-icons:before {
  content: "\ea2b";
}
.cg-icon-media:before {
  content: "\ea2c";
}
.cg-icon-menus:before {
  content: "\ea2d";
}
.cg-icon-messages:before {
  content: "\ea2e";
}
.cg-icon-newsletters:before {
  content: "\ea2f";
}
.cg-icon-orders:before {
  content: "\ea30";
}
.cg-icon-playlists:before {
  content: "\ea31";
}
.cg-icon-preferences:before {
  content: "\ea32";
}
.cg-icon-products:before {
  content: "\ea33";
}
.cg-icon-releases:before {
  content: "\ea34";
}
.cg-icon-shipping:before {
  content: "\ea35";
}
.cg-icon-stickers:before {
  content: "\ea36";
}
.cg-icon-support:before {
  content: "\ea37";
}
.cg-icon-templates:before {
  content: "\ea38";
}
.cg-icon-vouchers:before {
  content: "\ea39";
}
.cg-icon-cash:before {
  content: "\ea3a";
}
.cg-icon-card:before {
  content: "\ea3b";
}
.cg-icon-stripe:before {
  content: "\ea3c";
}
.cg-icon-paypal:before {
  content: "\ea3d";
}
.cg-icon-cheque:before {
  content: "\ea3e";
}
.cg-icon-bank-transfer:before {
  content: "\ea3f";
}
/* // listing-history/	BA40
// tooltip	BA41
// article-home	BA42 */
.cg-icon-padlock:before {
  content: "\ea43";
}
.cg-icon-notifications:before {
  content: "\ea44";
}
/* // unread	BA45 */
.cg-icon-inventory:before {
  content: "\ea46";
}
.cg-icon-domain:before {
  content: "\ea47";
}
.cg-icon-warning:before {
  content: "\ea48";
}
.cg-icon-note:before {
  content: "\ea49";
}
.cg-icon-open-tab:before {
  content: "\ea4F";
}
.cg-icon-link:before {
  content: "\ea50";
}
/* // generate-pdf	BA4A */
.cg-icon-download:before {
  content: "\ea4B";
}
/* // dropdown	BA4C
// arrows	BA4D
// camera	BA4E
// 	BA4F
// 	BA50

// 	BA51 */
.cg-icon-offline:before {
  content: "\ea51";
}
.cg-icon-credit-note:before {
  content: "\ea52";
}
/* // 	BA53 */
.cg-icon-item-page:before {
  content: "\ea54";
}
.cg-icon-arrow-back:before {
  content: "\ea55";
}
.cg-icon-player-options:before {
  content: "\ea56";
}
.cg-icon-chevron-l:before {
  content: "\ea57";
}
.cg-icon-chevron-r:before {
  content: "\ea58";
}
.cg-icon-book:before {
  content: "\ea59";
}
.cg-icon-market-rates:before {
  content: "\ea5a";
}
.cg-icon-seo:before {
  content: "\ea5B";
}
.cg-icon-discogs:before {
  content: "\ea60";
}
.cg-icon-ebay:before {
  content: "\ea61";
}
.cg-icon-facebook:before {
  content: "\ea62";
}
.cg-icon-instagram:before {
  content: "\ea63";
}
.cg-icon-soundcloud:before {
  content: "\ea64";
}
.cg-icon-mixcloud:before {
  content: "\ea65";
}
.cg-icon-bandcamp:before {
  content: "\ea66";
}
.cg-icon-twitter:before {
  content: "\ea67";
}
.cg-icon-youtube:before {
  content: "\ea68";
}
.cg-icon-mail:before {
  content: "\ea69";
}
.cg-icon-spotify:before {
  content: "\ea6A";
}
.cg-icon-apple-music:before {
  content: "\ea6B";
}
.cg-icon-phone:before {
  content: "\ea6C";
}
.cg-icon-mail:before {
  content: "\ea6D";
}
.cg-icon-maps:before {
  content: "\ea6E";
}

/* FEED UNICODES */

.cg-icon-feed-fav:before {
  content: "\eb00";
}
.cg-icon-feed-clock:before {
  content: "\eb01";
}
.cg-icon-feed-player:before {
  content: "\eb02";
}
.cg-icon-feed-player-stop:before {
  content: "\eb03";
}
