#uploader {
  & > .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) * 2);
    .left,
    .right {
      display: flex;
      align-items: center;
      a {
        margin: 0 var(--gutter);
      }
    }
  }
  .error {
    color: $cg-red;
    margin: var(--gutter) 0;
  }
  .submitUpload {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 200px;
    border: dashed grey 1px;
  }
  .upload {
    #dropzone {
      width: 100%;
      height: 200px;
      border: dashed grey 1px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        text-transform: uppercase;
      }
    }
    .header {
      display: flex;
      justify-content: flex-start;
    }
  }
  .rows {
    display: grid;
    grid-gap: var(--gutter);
    .row {
      p {
        margin: 0;
      }
      .header {
        display: flex;
        justify-content: space-between;
      }
      .ok {
        color: $cg-green;
      }
      .error {
        color: $cg-orange;
      }
      .content {
        .fields {
          .entries {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }
        }
      }
    }
  }
}
