$cg-purple-dark: #5236ff; // legacy
$cg-purple: #5236ff;
$cg-purple-light: #e4e4ff; // legacy

$cg-green-dark: #00865e; // legacy
$cg-green: #00c48a;
$cg-green-light: #d4efe7; // legacy

$cg-red: #ff5353; // legacy

$cg-orange: #ff7d4c;
$cg-orange-light: #ffdcc6; // legacy

$cg-blue-dark: #00a2ff; // legacy
$cg-blue: #76ccff; // legacy
$cg-blue-light: #d8f1ff; // legacy
$cg-blue-lighter: #d8f1ff; // legacy

$cg-white: #ffffff;
$cg-yellow: #efff5a; // legacy
$cg-yellow-light: #fbffd6; // legacy

$cg-editor-black: #121212; // legacy

$cg-grey-darkest: #161616; // legacy
$cg-grey-darker: #2e2d2d; // legacy
$cg-grey-dark: #4a4a4a; // legacy
$cg-grey: #7a7a7a; // legacy
$cg-grey-light: #9d9d9d; // legacy
$cg-grey-lighter: #d7d7d7; // legacy
$cg-grey-lightest: #f7f7f7; // legacy

$cg-black: #000;
