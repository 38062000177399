#itemPage {
  &.product > .item > .content > .right > .threeColumns {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 2fr;
  }
  & > .item {
    & > .content {
      display: grid;
      grid-gap: calc(var(--gutter) * 2);
      grid-template-columns: 1fr 4fr;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
      margin-bottom: calc(var(--gutter) * 2);
      & > .left {
        #imageRatio {
          display: flex;
          justify-content: center;
          margin-top: 5px;
        }
        img#mainImage {
          width: 100%;
        }
        .images {
          margin-top: var(--gutter);
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: var(--gutter);
          img {
            width: 100%;
          }
        }
      }
      & > .right {
        & > * {
          margin-bottom: calc(var(--gutter) * 2);
        }
        & > .header {
          display: flex;
          justify-content: space-between;
          @include lessThan(md) {
            flex-direction: column;
          }
          h1,
          h2 {
            font-size: $text-lg;
          }
          .labels,
          .artists,
          .manufacturer {
            h2 {
              font-weight: 400;
            }
          }
          .right {
            justify-self: flex-end;
            flex-wrap: nowrap;
            display: flex;
            button {
              margin-left: var(--gutter);
            }
            // @include lessThan(lg) {
            //   margin-top: calc(var(--gutter) * 1);
            //   button {
            //     margin: 0;
            //     margin-right: var(--gutter);
            //   }
            // }
          }
        }
        .buttonsAndLinks {
          display: block;
          .buttons {
            display: flex;
            justify-content: flex-start;
            .cg-button {
              margin-right: var(--gutter);
              &.withIcon {
                display: flex;
                align-items: center;
                i {
                  line-height: 0.8;
                  margin-left: 5px;
                }
              }
            }
            margin-bottom: calc(var(--gutter) * 1);
          }
          .links {
          }
        }
        .threeColumns {
          display: grid;
          grid-gap: var(--gutter);
          grid-template-columns: 1fr 1fr 1fr;
          @include lessThan(md) {
            grid-template-columns: 1fr;
          }
          h2 {
            font-size: $text-md;
            margin: 0;
          }
          & .header {
            display: flex;
            justify-content: flex-start;
            margin-bottom: calc(var(--gutter) / 1);
            align-items: flex-start;
            span {
              margin: 0 5px;
            }
            button {
              padding: 0;
              font-size: revert;
            }
          }
          .left {
            p {
              margin-bottom: calc(var(--gutter) / 4);
            }
          }
          .middle {
            .shortDescription {
              margin-bottom: calc(var(--gutter) * 2);
            }
            .description {
              iframe {
                max-width: 100%;
              }
              button.seeMore {
                background-color: transparent;
                border: none;
                padding: 0;
                margin-top: var(--gutter);
              }
            }
          }
          .right {
            .tracklist {
              .track {
                display: flex;
                justify-content: space-between;
                p {
                  margin-bottom: calc(var(--gutter) / 4);
                }
              }
              .playAndTrackName {
                display: flex;
                align-items: center;
                p {
                  margin: 0;
                }
                button {
                  padding: 2px 5px;
                }
              }
              .trackHeader {
                font-weight: bold;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
  .listings {
    #listingsHeader {
      h2,
      a button {
        margin: 0;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--gutter);
    }
  }
  .statistics {
    .sale {
      &.header {
        font-weight: bold;
      }
      display: grid;
      grid-template-columns: 1fr 1fr 2fr 1fr 1fr 1fr;
      p {
        margin: 0;
      }
      .unavailable {
        color: $cg-red;
      }
    }
    #statisticsHeader {
      .salesExport {
        display: flex;
        button {
          margin-left: var(--gutter);
        }
      }
      display: flex;
      justify-content: space-between;
    }
  }
}

#itemEdit {
  .required .inputGroup input,
  .required .inputGroup button,
  .required .select__control {
    border-color: $cg-red;
  }
  #headerInfoText {
    margin: 0;
  }
  & > .header {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: space-between;
    align-items: center;
    .right,
    .left {
      display: flex;
      align-items: center;
      @include lessThan(lg) {
        justify-content: space-between;
        margin-bottom: calc(var(--gutter) / 2);
      }
      button {
        margin-left: 1em;
        @include lessThan(lg) {
          margin: 0;
        }
      }
    }
  }
  form#itemForm {
    display: grid;
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-column-gap: var(--gutter);
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
    #information {
      #fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: var(--gutter);
        grid-gap: var(--gutter);
        .rowFields {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        @include respondToMax(lg) {
          grid-template-columns: 1fr !important;
          display: grid;
        }
        .descriptionEditor {
          margin-bottom: var(--gutter);
        }
        .giftCard {
          .header {
            margin-bottom: var(--gutter);
          }
          .giftCardEditor {
            .inputs {
              display: grid;
              grid-gap: var(--gutter);
              justify-content: space-between;
              label.row {
                display: flex;
                flex-direction: row;
              }
            }
          }
          label.row {
            flex-direction: row;
          }
        }
      }
    }
    #images {
      .entries {
        display: grid;
        grid-template-columns: 1fr;
        grid-column-gap: 1em;
        grid-row-gap: calc(var(--gutter) * 2);
        .image {
          cursor: grab;
          .imageOptions {
            margin-top: var(--gutter);
            display: grid;
            grid-gap: var(--gutter);
            grid-template-columns: 1fr 2fr;
          }
        }
      }
      #imageDropzone {
        margin-bottom: var(--gutter);
        border: 2px dotted $cg-grey;
        text-align: center;
        padding: 50% var(--gutter);
      }
    }
  }
}

#releaseAdd {
  &.mobile {
    .header {
      .right {
        .csvUpload {
          display: none;
        }
      }
    }
    .suggestions {
      grid-template-columns: repeat(2, 1fr);
    }
    .addBlocks {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
    }
    .right {
      button {
        margin-left: var(--gutter);
      }
    }
  }
  .content {
    #noToken {
      text-align: center;
    }
    .container {
      padding: var(--gutter);
      h2 {
        text-align: left;
        margin-bottom: 1em;
      }
      #similar {
        text-align: center;
        margin-top: calc(var(--gutter) / 2);
        a {
          color: $cg-red;
        }
      }
      #discogsLink {
        margin-top: calc(var(--gutter) / 2);
        text-align: center;
      }
      #priceSuggestions {
        button {
          height: auto;
          font-size: $text-sm;
        }
        p {
          margin: var(--gutter) 0;
        }
        #suggestions {
          display: grid;
          grid-template-columns: 1fr;
          @include respondTo(md) {
            grid-template-columns: 1fr 1fr;
          }
          @include respondTo(lg) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
          grid-gap: var(--gutter);
        }
      }
      .inputGroup {
        img {
          position: absolute;
          top: 0;
          left: 0;
          padding: 6px;
          width: auto;
          height: 22px;
          border: none;
        }
        input {
          border: none;
          padding-left: 40px;
          border: 1px solid inherit;
          width: 100%;
          margin: 0;
        }
      }
      .filters {
        margin-top: var(--gutter);
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-end;
        .clear {
          text-align: right;
        }
      }
    }
    margin-bottom: var(--gutter);
  }
  .suggestions {
    margin-top: var(--gutter);
    display: grid;
    grid-gap: calc(var(--gutter) / 2);
    grid-template-columns: repeat(5, 1fr);
    .suggestion {
      font-size: $font-size-sm;
      padding: 5px;
      &:hover {
        cursor: pointer;
      }
      & > * {
        margin-bottom: 5px;
      }
    }
  }
  .withDiscogsId {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    small {
      line-height: 1;
    }
    form {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    p {
      margin: 0;
    }
  }
  .listingContainer {
    .listingHeader {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .images {
        margin-left: var(--gutter);
        display: flex;
        img {
          margin-right: var(--gutter);
          max-width: 40px;
        }
      }
    }
  }
  .clear {
    margin: var(--gutter);
    display: flex;
    justify-content: center;
  }
  .addBlocks {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 2fr 1fr 1fr 1fr;
    .addBlock {
      display: flex;
      align-items: flex-start;
      text-align: start;
      .title {
        font-weight: bold;
      }
    }
  }
}

#bookSearchModal {
  & > .header {
    margin-bottom: var(--gutter);
    h2 {
      margin: 0;
    }
    .left {
      display: flex;
      align-items: flex-start;
    }
  }

  & > .content {
    form {
      margin-bottom: var(--gutter);
    }
    .results {
      display: grid;
      grid-gap: calc(var(--gutter) / 2);
      .entries {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: calc(var(--gutter));
        .result {
          cursor: pointer;
          img {
            margin-bottom: var(--gutter);
          }
        }
      }
    }
  }
}

#editDescriptionsEditorModal {
  form {
    & > * {
      margin-bottom: var(--gutter);
    }
  }
}

.zoomZone [data-rmiz-modal-overlay="visible"] {
  background-color: transparent;
}

.addItemButton {
  // height: 35px;
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
  canvas {
    border-radius: 5px;
    overflow: auto;
    overflow-clip-margin: border-box;
  }
}

#tracklistEditor {
  border-radius: 0.25em;
  @include respondToMin(lg) {
    grid-column-start: 1;
    grid-column-end: span 2;
  }
  #tracklist {
    display: grid;
    grid-row-gap: calc(var(--gutter) / 2);
    #tracklistHeader,
    .track {
      display: grid;
      grid-column-gap: calc(var(--gutter) / 2);
      grid-template-columns: 0.2fr 1.3fr 1fr 4fr 1fr 3fr;
      @include respondToMax(lg) {
        grid-template-columns: 1fr 1fr;
      }
      text-transform: capitalize;
      .duration,
      .position {
        max-width: 80px;
      }
      .snippet {
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        @include respondToMax(lg) {
          display: none;
        }
        &.uploaded {
          .dropzone {
            border-color: $cg-green;
          }
        }
        p.playzone {
          padding: 0.2em;
          width: 20%;
        }
        .dropzone {
          border: 2px dotted $cg-grey;
          height: 100%;
          width: 100%;
          p {
            text-align: center;
            padding: 0.5em;
          }
        }
      }
      .delete {
        margin: 0;
        height: auto;
        padding: 5px 0px;
      }
    }
  }
}

#tracklistEditorNew {
  border-radius: 0.25em;
  #tracklist {
    display: grid;
    grid-row-gap: calc(var(--gutter) / 2);
    #tracklistHeader,
    .track {
      display: grid;
      grid-column-gap: calc(var(--gutter) / 2);
      grid-template-columns: 0.2fr 1.3fr 1fr 4fr 1fr 3fr;
      @include respondToMax(lg) {
        grid-template-columns: 1fr 1fr;
      }
      text-transform: capitalize;
      .duration,
      .position {
        max-width: 80px;
      }
      .snippet {
        display: grid;
        grid-template-columns: 1fr 5fr;
        align-items: center;
        @include respondToMax(lg) {
          display: none;
        }
        &.uploaded {
          .dropzone {
            border-color: $cg-green;
          }
        }
        p.playzone {
          padding: 0.2em;
          width: 20%;
        }
        .dropzone {
          border: 2px dotted $cg-grey;
          height: 100%;
          width: 100%;
          p {
            text-align: center;
            padding: 0.5em;
          }
        }
      }
      .delete {
        margin: 0;
        height: auto;
        padding: 5px 0px;
      }
    }
  }
}

.identifiers {
  margin: var(--gutter) 0;
  .identifier {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1fr 0.1fr;
    margin-bottom: var(--gutter);
    align-items: flex-end;
  }
}

.formatEditor {
  .formats {
    .format {
      .header {
        & > .content {
          display: flex;
          align-items: center;
          & > *,
          p {
            margin: 0;
            margin-right: 5px;
          }
        }
      }
      .descriptions {
        margin-top: var(--gutter);
        display: flex;
        flex-direction: column;
        .description {
          display: flex;
          margin-bottom: 10px;
          justify-content: space-between;
        }
      }
    }
  }
}

.artistEntries,
.labelEntries {
  .header {
    display: flex;
    justify-content: flex-end;
  }
  .artistsSearch {
    img {
      max-width: 40px;
      margin-right: var(--gutter);
    }
    .suggestion {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    img {
      max-width: 40px;
    }
  }
}
