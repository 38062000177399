#helpPanel {
  padding-top: calc(var(--navbarHeight) * 1 + var(--gutter));
  padding-bottom: calc(var(--gutter) * 1);
  border-radius: 0;
  width: 25vw;
  height: 100vh;
  box-sizing: border-box;
  overflow-y: scroll;
  position: sticky;
  top: 0;
  right: 0;
  &.closed {
    display: none;
  }
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--gutter) * 2);
    font-size: $text-md;
  }
  .content {
    font-size: $text-sm;
    h2,
    h3 {
      margin-bottom: calc(var(--gutter) / 2);
    }
    h2 {
      font-size: $text-md;
    }
    h3 {
      font-size: $text-md;
      line-height: 1.2;
    }
    p {
      margin: 0;
      min-height: 1.53em;
    }
    code {
      font-family: "Simplon mono light";
      padding: 3px 5px;
      border-radius: 5px;
    }
    b {
      font-weight: bold;
    }
    ol,
    ul {
      padding-left: var(--gutter);
      margin: calc(var(--gutter) / 2) 0;
      li {
        list-style-type: disc;
      }
    }
    a {
      text-decoration: underline;
    }
    textarea {
      width: 100%;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
    }
    .intercom-interblocks-callout {
      padding: 5px;
      background-color: transparent !important;
    }
    .video {
      font-weight: bold;
      font-size: $text-md;
      display: flex;
      justify-content: center;
      margin-bottom: calc(var(--gutter) * 2);
      button {
        i {
          margin-right: 5px;
          font-size: 15px;
        }
        display: flex;
        align-items: center;
      }
    }
  }
}

.helpVideoModal {
  video {
    width: 100%;
  }
}
