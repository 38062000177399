:root {
  --gutter: 10px;
  --navbarHeight: 65px;
  --menuWidthOpen: 180px;
  --menuWidthClose: 80px;
  --menuSize: 0;
  --menuSizeCollapsed: 0;
  @media only screen and (min-width: 821px) {
    --gutter: 13px;
    --navbarHeight: 70px;
    --menuSize: 196px;
    --menuSizeCollapsed: 62px;
    --bottomEntriesBtnHeight: 70px;
  }
  @media only screen and (min-width: 1080px) {
    --gutter: 15px;
  }
}

$cg-font: var(--cg-font);

$cg-success: $cg-green;
$cg-warning: $cg-orange;
$cg-danger: $cg-red;
$cg-info: $cg-blue;
$cg-black: black;

$font-size-xs: 9px;
$font-size-sm: 11px;
$font-size-md: 13px;
$font-size-input: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 30px;

/***** new font variables *****/

$text-xxs: 8px;
$text-xs: 10px;
$text-sm: 12px;
$text-md: 14px;
$text-lg: 20px;
$text-xl: 30px;
// $text-size-xxl: 30px;

.text-s {
  font-size: $text-sm;
  font-weight: normal;
}
.text-s-medium {
  font-size: $text-sm;
  font-weight: 600;
  line-height: $text-sm + 3px;
}
.text-m {
  font-size: $text-md;
  font-weight: normal;
  line-height: $text-md + 2px;
}
.text-m-medium {
  font-size: $text-md;
  font-weight: 600;
  line-height: $text-md + 2px;
}
.text-l {
  font-size: $text-lg;
  font-weight: normal;
  line-height: $text-lg + 2px;
}
.text-l-medium {
  font-size: $text-lg;
  font-weight: 600;
}
.text-xl {
  font-size: $text-xl;
  font-weight: normal;
  line-height: $text-xl + 5px;
}

$border-radius: 10px;

$footerHeight: 4rem;
