.theme-dark {
  #article {
    & > .header {
      background-color: $cg-grey-darkest !important;
    }
  }
}
.theme-light {
  #article {
    & > .header {
      background-color: #fff !important;
    }
  }
}

#article {
  & > .header {
    position: sticky;
    top: calc(var(--navbarHeight));
    padding: var(--gutter);
  }
  & > .content {
    .rows {
      .layoutProposal {
        padding: calc(var(--gutter) * 2);
        h2 {
          text-align: center;
          margin-bottom: calc(var(--gutter) * 2);
        }
        .layouts {
          display: grid;
          justify-content: center;
          grid-template-columns: 1fr 1fr 1fr;
          .layout {
            display: flex;
            flex-direction: column;
            & {
              cursor: pointer;
            }
            &:hover {
              p.description {
                color: black;
              }
              .oneColumn,
              .twoColumn,
              .threeColumn {
                color: black;
                border-color: black;
                &::after,
                &::before {
                  background-color: black;
                }
              }
            }
            .oneColumn {
              width: 10rem;
              height: 5rem;
              margin: 0 auto;
              border: 1px solid $cg-grey;
            }
            .twoColumn {
              width: 10rem;
              height: 5rem;
              margin: 0 auto;
              border: 1px solid $cg-grey;
              position: relative;
              &::after {
                left: 50%;
              }
              &::after {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background: $cg-grey;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                position: absolute;
              }
            }
            .threeColumn {
              width: 10rem;
              height: 5rem;
              margin: 0 auto;
              border: 1px solid $cg-grey;
              position: relative;
              &::before {
                left: 33%;
              }
              &::after {
                right: 33%;
              }
              &::after,
              &::before {
                content: "";
                display: block;
                width: 1px;
                height: 100%;
                background: $cg-grey;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                position: absolute;
              }
            }
            p.description {
              color: $cg-grey;
              text-align: center;
              margin-top: calc(var(--gutter) / 2);
            }
          }
        }
      }
    }
  }
}

#articles {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: calc(var(--gutter) * 2);
    #headerButtons {
      display: flex;
      flex-direction: row;
      button {
        margin-left: calc(var(--gutter) / 2);
      }
    }
  }
  .createYourFirstArticle {
    justify-content: center;
    display: flex;
    & > button {
      height: auto;
      margin-top: 60px;
    }
  }
  .articlesTable {
    .article {
      border: 1px solid transparent;
      &:hover {
        cursor: pointer;
        border: 1px solid $cg-blue;
      }
      &.header {
        font-weight: bold;
        margin: 0;
      }
      svg {
        margin-right: 5px;
      }
      p {
        margin-bottom: 0;
      }
      display: grid;
      grid-gap: var(--gutter);
      align-items: center;
      grid-template-columns: 0.2fr 1fr 1fr 1fr 1fr 0.5fr;
      @include lessThan(md) {
        grid-template-columns: 1fr 1fr;
      }
      padding: var(--gutter);
      .buttons {
        button {
          margin-right: 5px;
        }
      }
      .tags {
        display: block;
        .tag {
          color: inherit;
          background-color: inherit;
          border-color: $cg-grey;
        }
      }
    }
  }
  .filters {
    .entries {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: var(--gutter);
    }
  }
}

#articleEditorModal {
  .titleAndHandle {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
    display: block;
    & > * {
      margin-bottom: var(--gutter);
    }
  }
}
