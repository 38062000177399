#tutorial {
  width: 300px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 999;
  border-radius: $border-radius;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .top {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      margin: 0;
      border: none;
      background-color: transparent;
      padding: 4px;
    }
  }

  .steps {
    padding-bottom: 15px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    &.minimized {
      display: none;
    }
    &.open {
      display: block;
    }

    a:hover {
      text-decoration: none;
    }

    .step {
      display: grid;
      grid-template-columns: 32px 268px;
      align-items: center;
      &:last-child {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
      p {
        margin-bottom: 0;
      }

      &.completed {
      }

      .state {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .text {
        display: flex;
        align-items: center;
      }
    }
  }
}
