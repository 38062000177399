.tabView {
  .tabList {
    display: flex;
    margin-bottom: var(--gutter);
    .tab {
      border: none;
      &:hover {
        cursor: pointer;
      }
      padding: var(--gutter);
      &:focus:after {
        background: none;
      }
    }
  }
}
