#preferences {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr 5fr;
  .tabs {
    .content {
      position: sticky;
      top: calc(55px + calc(var(--gutter) * 2));
      & > .active {
        text-decoration: underline;
        font-weight: bold;
      }
      .linkEntry {
        padding: 5px calc(var(--gutter) / 2);
      }
    }
  }
  button.submitMainInformation {
    min-width: 150px;
    margin-top: var(--gutter);
  }

  .cg-zone {
    margin-bottom: calc(var(--gutter) * 2);
  }

  .preferencesFields {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: calc(var(--gutter) / 1);
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
    }
  }

  .preferencesForm {
    margin-bottom: var(--gutter);
    .subSection {
      margin: calc(var(--gutter) / 2) 0;
      h4 {
        margin-bottom: calc(var(--gutter) / 4);
      }
    }
  }

  .ql-container {
    height: 300px;
  }

  #information {
    .spacedRows {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
    .emailInputs {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
    .socials {
      .select {
        margin-bottom: var(--gutter);
      }
      .entries {
        margin-bottom: calc(var(--gutter) * 2);
        .entry {
          display: grid;
          grid-gap: var(--gutter);
          align-items: flex-end;
          i:not(.cg-icon-burger-close) {
            font-size: $text-xl;
          }
          grid-template-columns: 30px 3fr 30px;
          margin-bottom: calc(var(--gutter) / 2);
        }
      }
    }
  }

  .externalServices {
    .bandcamp {
      .tutorial {
        ul {
          margin: var(--gutter) 0;
          padding-left: calc(var(--gutter) * 2);
          list-style: number;
        }
        margin-bottom: var(--gutter);
      }
      form > * {
        margin-bottom: var(--gutter);
      }
    }
    .preferencesFields {
      .cg-checkbox {
        margin-bottom: var(--gutter);
      }
    }
  }

  #legal {
    .facebookCSVLink {
      background-color: $cg-grey-lightest;
    }
  }

  #taxes {
    padding: calc(var(--gutter) * 1) 0;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .country {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
      margin-bottom: calc(var(--gutter) * 2);
      .left {
        .countrySelect,
        .zoneSelect {
          margin-bottom: calc(var(--gutter) * 2);
        }
      }
      .right {
        .taxRules {
          display: flex;
          flex-direction: column;
          .rule {
            margin-bottom: calc(var(--gutter) / 2);
          }
        }
      }
    }
    #taxEditor {
      .rows {
        display: grid;
        grid-gap: calc(var(--gutter) / 1);
        .row {
          display: grid;
          grid-gap: calc(var(--gutter) / 1);
          grid-template-columns: 1fr 1fr 1fr 0.5fr;
          @include lessThan(md) {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }

  .summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "left right right right";
    grid-gap: var(--gutter);
    @include for-phone-only {
      grid-template-columns: 1fr;
      grid-template-areas: "left" "right";
    }
    h3 {
      margin: 0;
    }
    .left {
      grid-area: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      img {
        border-radius: 50%;
        width: 90%;
        padding: 5%;
        margin-bottom: var(--gutter);
      }
    }
    .right {
      grid-area: right;
      display: grid;
      grid-gap: var(--gutter);
      hr {
        margin: 0;
        border-color: $cg-grey-lighter;
      }
      .header {
        .top {
          display: flex;
          justify-content: space-between;
          @include for-phone-only {
            flex-direction: column;
          }
        }
      }
      .stats {
        display: flex;
        justify-content: space-between;
        @include for-phone-only {
          display: grid;
          grid-gap: var(--gutter);
        }
        h3 {
          @extend .text-l;
        }
      }
      .quickstart {
        .since {
          margin-bottom: var(--gutter);
        }
        .steps {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: var(--gutter);
          @include for-phone-only {
            grid-template-columns: 1fr;
            grid-gap: var(--gutter);
          }
          p {
            margin: 0;
          }
          .step {
            @extend .text-m-medium;
            &:not(.completed) {
              color: $cg-orange;
            }
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .state {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
            }
          }
        }
      }
      .methods {
        display: flex;
        justify-content: flex-start;
        .step {
          p {
            @extend .text-m-medium;
          }
          margin-right: var(--gutter);
          svg {
            stroke: black;
            margin-right: 10px;
          }
          display: flex;
        }
      }
    }
  }

  .restartMembership {
    button {
      height: auto;
      font-size: $font-size-md;
      padding: 10px;
    }
  }

  .shopAdmins {
    border-top-left-radius: 0;
    .error {
      color: $cg-red;
    }
    p {
      margin: 0;
    }
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: calc(var(--gutter) * 2);
      .left {
        display: flex;
        align-items: center;
      }
    }
    .adminList {
      display: grid;
      grid-gap: var(--gutter);
      hr {
        margin: 0;
      }
      .admin {
        &.editing {
          & > * {
            margin-bottom: var(--gutter);
          }
          & > .content {
            display: flex;
            justify-content: flex-start;
            & > * {
              display: grid;
              grid-gap: var(--gutter);
              margin-right: var(--gutter);
            }
          }
          .footer {
            display: flex;
            justify-content: flex-start;
            button {
              margin-right: var(--gutter);
            }
          }
        }
        .tempPassword {
          padding: 5px;
          font-weight: bold;
          background-color: white;
        }
        &:not(.editing) {
          display: grid;
          grid-template-columns: 2fr 2fr 1fr 1fr;
          @include for-phone-only {
            grid-template-columns: repeat(3, 1fr);
            .added,
            .email {
              display: none;
            }
          }
          align-items: center;
          p.role {
            text-transform: capitalize;
          }
          .actions {
            display: flex;
            @include for-phone-only {
              .edit {
                display: none;
              }
            }
            button {
              margin-left: var(--gutter);
            }
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .membership {
    .header {
      margin-bottom: var(--gutter);
      display: flex;
      align-items: baseline;
      @include for-phone-only {
        flex-direction: column;
      }
      h2 {
        margin-right: var(--gutter);
      }
    }
    .plans {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: var(--gutter);
      @include for-phone-only {
        grid-template-columns: 1fr;
      }
      .plan {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        &.active {
          border: 1px solid $cg-blue;
          .title > h2 {
            color: $cg-blue;
            margin: 0;
          }
        }
        &:not(.active) {
          color: $cg-grey;
        }
        p.description {
          color: $cg-blue;
          margin: 0;
        }
        .title {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
        }
        padding: var(--gutter);
        border-radius: $border-radius;
        .price {
          font-weight: normal;
          h2 {
            font-size: 40px;
            font-weight: inherit;
          }
          span {
            font-size: $font-size-md;
          }
          margin-bottom: calc(var(--gutter) * 4);
          margin-top: calc(var(--gutter) * 2);
        }
      }
    }
    .nextPaymentDate {
      text-align: center;
      padding: calc(var(--gutter) * 2);
      @include for-phone-only {
        padding: calc(var(--gutter) * 1) 0;
      }
    }
    .invoices {
      .history {
        text-align: center;
        position: relative;
        padding: var(--gutter);
      }
      @include for-phone-only {
        h2 {
          margin-bottom: calc(var(--gutter) * 2);
        }
      }
      .pagination {
        li {
          padding: 0;
        }
        nav {
          margin-left: var(--gutter);
        }
      }
      .invoicesTable {
        & > .table {
          display: grid;
          .invoiceTableEntry {
            margin-bottom: 0;
            .date {
              @include for-phone-only {
                display: none;
              }
            }
            .fromNow {
              color: $cg-grey;
              margin-left: 10px;
              @include for-phone-only {
                display: none;
              }
            }
            .status.unpaid {
              color: $cg-red;
            }
            .description > .title {
              @include for-phone-only {
                display: none;
              }
            }
            .actions {
              text-align: center;
              @include for-phone-only {
                display: none;
              }
            }
            p {
              margin: 0;
            }
            &.header {
              font-weight: bold;
            }
            display: grid;
            grid-gap: var(--gutter);
            grid-template-columns: 2fr 2fr 1fr 0.5fr 0.5fr;
            align-items: center;
            @include for-phone-only {
              grid-template-columns: 0.5fr 1fr 0.3fr;
            }
          }
        }
      }
    }
  }

  .tabView {
    .tabList {
      display: flex;
      .tab {
        &:hover {
          cursor: pointer;
        }
        padding: var(--gutter);
      }
    }
  }

  .referrals {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include for-phone-only {
      grid-template-columns: 1fr;
    }
    .left,
    .right {
      .header {
        display: flex;
      }
      & > * {
        margin-bottom: calc(var(--gutter) * 2);
      }
      .blocks {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        .block {
          .number {
            text-align: center;
            font-size: $text-xl;
            margin-bottom: var(--gutter);
          }
          .label {
            @extend .text-s;
            color: $cg-grey;
            text-align: center;
          }
        }
      }
      .invite {
        display: flex;
        align-items: flex-end;
      }
      .invites {
        display: grid;
        grid-gap: var(--gutter);
        p.capsule {
          width: fit-content;
          background-color: $cg-green-light;
          color: $cg-grey;
          padding: 10px;
          border-radius: 10px;
        }
      }
    }
  }

  .paymentMethods {
    &.hasNoMethod {
      border: 1px dashed $cg-red;
      padding: var(--gutter);
      border-radius: $border-radius;
      margin-bottom: calc(var(--gutter) * 2);
      h2,
      h3 {
        color: $cg-red;
      }
      h3 {
        text-align: center;
        font-weight: normal;
        @extend .text-l;
        margin-bottom: calc(var(--gutter) * 2);
      }
      @include for-phone-only {
        text-align: center;
        h2 {
          margin-bottom: calc(var(--gutter) * 2);
        }
        .right {
          justify-content: center;
        }
      }
    }
    & > .header {
      display: flex;
      justify-content: space-between;
      @include for-phone-only {
        flex-direction: column;
      }
      margin-bottom: calc(var(--gutter) * 2);
      .right {
        display: flex;
        button {
          margin-left: var(--gutter);
          @include for-phone-only {
            margin-left: 0;
            margin-right: var(--gutter);
          }
        }
      }
    }
    .sources {
      display: grid;
      grid-gap: var(--gutter);
      .source {
        margin: 0;
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr 1fr;
        @include for-phone-only {
          display: flex;
          justify-content: space-between;
          .type,
          .default,
          .expires {
            display: none;
          }
        }
        align-items: center;
        p {
          margin: 0;
        }
        span {
          text-align: right;
        }
        & > :last-child {
          display: flex;
          justify-content: flex-end;
        }
      }
    }
    .addingPaymentMethod {
      font-size: 15px;
      & > .header {
        display: flex;
        justify-content: flex-start;
        .left {
          display: flex;
        }
        h2,
        button {
          margin: 0;
        }
        button {
          background-color: transparent;
          border: none;
        }
      }
      .footer {
        display: flex;
        justify-content: flex-end;
        img {
          width: 50px;
        }
      }
      .ibanForm,
      .cardForm {
        & > * {
          margin: var(--gutter) 0;
        }
        p {
          line-height: 1.3;
          font-size: $font-size-sm;
        }
        p.mandate-acceptance {
          @include for-tablet-portrait-up {
            width: 600px;
          }
        }
        .header {
          display: flex;
          .right {
            margin-left: 10px;
          }
        }
        .StripeElement {
          padding: 10px 12px;
          background-color: white;
          border: 1px solid transparent;
          border-radius: 10px;
          margin-top: var(--gutter);
          @include for-tablet-portrait-up {
            width: 400px;
          }
          color: red;

          -webkit-transition: box-shadow 150ms ease;
          transition: box-shadow 150ms ease;

          .InputElement::placeholder {
            color: red;
          }
        }

        .StripeElement--invalid {
          border-color: #fa755a;
        }

        .StripeElement--webkit-autofill {
          background-color: #fefde5 !important;
        }
      }
    }
  }

  .dangerZone {
    .header {
      display: flex;
      button {
        margin-left: var(--gutter);
      }
    }
    .resetData {
      button[type="submit"] {
        margin-top: var(--gutter);
      }
      .cg-checkbox {
        margin-top: 10px;
      }
    }
    .backofficeOptions {
      & > * {
        margin-bottom: calc(var(--gutter) * 1);
      }
    }
  }
}

#account {
  display: grid;
  grid-gap: calc(var(--gutter) * 2);
  &.noPaymentMethod:not(.blocked) {
    .paymentMethods {
      grid-row: 1 / 1;
    }
  }
}

#blockedAccount {
  grid-row: 1 / 1;
  text-align: center;
  padding: var(--gutter);
  border: 1px dashed $cg-orange;
  & > * {
    margin-bottom: var(--gutter);
  }
}

#payMyInvoiceModalContent {
  .noPaymentMethod {
    margin: var(--gutter) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  form {
    display: flex;
    flex-direction: column;
    & > * {
      margin: 10px 0;
    }
  }
}

#invoiceModalContent,
#restartMembershipModalContent {
  display: flex;
  flex-direction: column;
  form {
    display: grid;
    grid-gap: var(--gutter);
  }
  button.cancel {
    margin-top: var(--gutter);
  }
}

#addAdminModalContent,
#editProfileModal {
  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
    display: flex;
    flex-direction: column;
    & > * {
      margin-bottom: var(--gutter);
    }
    label {
      display: flex;
      flex-direction: column;
    }
    .inline {
      display: flex;
      justify-content: space-between;
    }
  }
}
