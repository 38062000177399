#player {
  margin-bottom: var(--gutter);
  &.hidden {
    display: none;
  }
  z-index: 1;
  height: auto;
  @include respondToMin(md) {
    height: 50px;
  }
  #playerContent {
    height: 100%;

    padding: 0 var(--gutter);
    display: grid;
    align-items: center;
    grid-gap: var(--gutter);
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "left ."
      "waveform right";
    @include respondTo(lg) {
      grid-template-columns: 2fr 5fr 2fr;
      grid-template-areas: "left waveform right";
    }
    #left {
      grid-area: left;
      display: flex;
      align-items: center;
      #artwork {
        img {
          height: auto;
          width: 20px;
        }
      }
      a {
        white-space: nowrap;
      }
      .loader {
        margin: 0;
        margin-left: calc(var(--gutter) * 2);
      }
      #playerTitle {
        margin-left: calc(var(--gutter) / 2);
        a {
          word-break: break-all;
        }
      }
    }

    #waveform {
      grid-area: waveform;
      width: 100%;
      overflow: hidden;
      & > wave {
        overflow: hidden;
      }
      wave {
        z-index: 1;
      }
    }
    #right {
      grid-area: right;
      text-align: right;
      button {
        @extend .reset;
        font-size: $text-lg;
      }
    }
  }
}
