#version {
  z-index: 999;
  position: fixed;
  bottom: var(--gutter);
  right: var(--gutter);
  background-color: $cg-blue;
  color: white;
  padding: var(--gutter);
  border-radius: $border-radius;
  .header {
    margin-bottom: var(--gutter);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    p {
      font-size: $font-size-lg;
      margin: 0;
    }
  }
  .content {
    text-align: center;
  }
}
