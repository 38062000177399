#developers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  .left {
    h2 {
      text-align: center;
      margin: calc(var(--gutter) * 2);
    }
    padding: var(--gutter);
  }
  .right {
    padding: var(--gutter);
    .entry {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);
    }
    h3 {
      margin-bottom: var(--gutter);
    }
    span {
      padding: 5px;
    }
  }
}
