#search {
  & > .header {
    margin-bottom: var(--gutter);
  }
  & > .content {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: repeat(8, 1fr);
      @include lessThan(lg) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include lessThan(md) {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

.mainSearch {
  @include lessThan(md) {
    min-width: 0;
  }
  padding: 5px;
  border-radius: $border-radius;
  .container {
    position: relative;
    .inputContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      i {
        height: 100%;
      }
      button {
        padding: 0;
      }
      input {
        padding: 5px;
        border: none;
        flex-grow: 2;
      }
    }
  }
  .suggestions {
    width: 100%;
    max-height: 40vh;
    overflow-y: scroll;
    position: absolute;
    top: calc(100% + 15px);
    border-radius: $border-radius;
    .suggestionEntry {
      border-radius: 0;
    }
    .suggestion {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      img {
        margin-right: 10px;
        width: 30px;
        height: auto;
      }
    }
  }
}
