.sp-wrapper {
  min-height: 80vh;
  color: inherit !important;
}

.themeEditor {
  h2 {
    margin: 0;
  }
  h3.sectionTitle {
    font-style: normal;
    font-weight: 600;
    font-size: $font-size-md;
  }
  a:hover {
    text-decoration: none;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    &.code {
      grid-template-columns: 30% 70%;
      @include lessThan(md) {
        grid-template-columns: 1fr;
      }
    }
  }
  .preview {
    position: sticky;
    top: var(--navbarHeight);
  }
  .selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    & > p {
      font-size: $font-size-md;
      margin-bottom: 0;
    }
    .values {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1px 2px;
      border-radius: 25px;
      p {
        margin-bottom: 3px;
      }
      button {
        padding: 1px 10px;
        font-weight: bold;
        font-size: 30px;
      }
    }
  }
  .colorsEditor {
    padding: var(--gutter);
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);
      align-items: center;
    }
    .content {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr;
    }
    button.cg-button {
      margin-top: var(--gutter);
    }
  }
  .color,
  .colorPicker {
    display: flex;
    justify-content: space-between;
    input {
      border-radius: 2px;
      border: none;
      outline: none;
    }
    .right {
      display: grid;
      grid-template-columns: 1fr 20px;
      .colorZone {
        border: 1px solid $cg-grey;
        width: 150px;
        height: 20px;
        cursor: pointer;
        border-radius: $border-radius;
      }
    }
  }
  .themeSettingsEditor {
    padding: var(--gutter);
    & > .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--gutter);
    }
    .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--gutter);
      section {
        margin-bottom: calc(var(--gutter) * 1);
        & > *,
        & .subsection > div {
          margin-bottom: calc(var(--gutter) / 1);
        }
        .subsection {
          h3 {
            margin-bottom: calc(var(--gutter) / 2);
          }
          margin-bottom: calc(var(--gutter) * 3);
        }
      }
    }
    .superHeader,
    .wantlist {
      & > * {
        margin-bottom: var(--gutter);
      }
    }
  }
  .themeButtonsEditor {
    padding: var(--gutter);
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--gutter);
    }
    .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--gutter);
    }
  }
  .fontsEditor {
    padding: var(--gutter);
    & > .content {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--gutter);
      .left {
        .addStyle {
          margin-top: var(--gutter);
        }
        .style {
          margin: calc(var(--gutter) * 1) 0;
          & > .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: calc(var(--gutter) * 1);
            h2 {
              font-size: $font-size-md;
              margin: 0;
            }
          }
          .styleForm {
            & > * {
              margin-bottom: var(--gutter);
            }
            .buttons {
              margin-top: calc(var(--gutter) * 2);
              display: flex;
              justify-content: space-between;
            }
            .twoColumns {
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: var(--gutter);
              input {
                max-width: 100px;
              }
            }
          }
        }
        & > .header {
          display: flex;
          justify-content: space-between;
          h1 {
            margin: 0;
          }
          margin-bottom: calc(var(--gutter) * 2);
        }
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);
      align-items: center;
    }
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      .font {
        .header {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .themePagesEditor {
    padding: var(--gutter);
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);
      align-items: center;
    }
    .content {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr;
      .layouts {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        .layout {
          &.active {
            background-color: $cg-blue;
          }
        }
      }
    }
  }
  & > .content > .admin {
    .preferencesFields > .inline {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    label {
      & > * {
        margin-top: 5px;
      }
    }
    #miscsEditor {
      label > select {
        display: block;
        height: 35px;
      }
    }
    #fontSizesEditor,
    #spacingsEditor,
    #miscsEditor {
      .fontSizes,
      .spacings {
        .fontSize {
          label {
            input {
              display: block;
            }
          }
        }
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }
    }
    #colors {
      margin: calc(var(--gutter) * 2) 0;
      #colorPresets {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: var(--gutter);
        .colorDefinition {
          display: flex;
          flex-direction: column;
          .content {
            display: flex;
            flex-direction: row;
            .pastille {
              border-radius: 5px;
              border: 1px solid black;
              width: 55px;
              height: 55px;
            }
            .data {
              margin-left: 5px;
              display: flex;
              flex-direction: column;
              .nameAndDelete {
                button {
                  margin: 0;
                  height: 25px;
                  margin-left: 5px;
                  border-radius: 0;
                }
                display: flex;
              }
            }
            p {
              margin: 0;
            }
            input {
              border-radius: 0;
              max-width: 80px;
              padding: 3px;
              margin-bottom: 5px;
            }
          }
        }
      }
      #colorAreasSection {
        .areaDefinition {
          display: flex;
          flex-direction: column;
          &.dragged {
            border: 2px solid $cg-black;
          }
          &:hover {
            cursor: grab !important;
          }
          .content {
            display: flex;
            flex-direction: column;
            .pastille {
              border-radius: 5px;
              width: 55px;
              height: 55px;
            }
            .data {
              display: flex;
              flex-direction: column;
              padding: 10px;
              border-radius: 5px;
              border: 2px solid;
              .colorSelection {
                margin-top: 10px;
                display: grid;
                grid-gap: 10px;
                align-items: flex-end;
                grid-template-columns: 1fr 1fr 0.2fr;
                input {
                  //
                  max-width: 80px;
                }
                button {
                  padding: 2px;
                  height: 35px;
                  margin: 0;
                }
                .select__control {
                  margin-top: 5px;
                  height: 35px;
                }
              }
            }
            p {
              margin: 0;
            }
            label.inputs {
              display: flex;
              flex-direction: column;
              input {
                height: 35px;
                border-radius: 0;
                margin-top: 5px;
                padding: 5px;
              }
            }
          }
        }
        .areas {
          display: grid;
          grid-gap: var(--gutter);
          grid-template-columns: repeat(4, minmax(150px, 1fr));
        }
      }
      #colorPresets {
        .presets {
          display: grid;
          grid-gap: var(--gutter);
          grid-template-columns: repeat(6, minmax(150px, 1fr));
        }
      }
    }
  }
  .themeMenu {
    min-height: calc(100vh - var(--navbarHeight) - (var(--gutter) * 2));
    // overflow-y: scroll;
    .menu > .header {
      display: flex;
      justify-content: space-between;
      padding: var(--gutter);
      align-items: center;
      .buttons > * {
        margin-left: var(--gutter);
      }
    }
    .entries {
      padding: var(--gutter);
      margin-bottom: var(--gutter);
      li,
      .entry {
        display: flex;
        align-items: flex-end;
        padding: 5px 0;
        font-size: $cg-text-md;
        @include lessThan(lg) {
          font-size: $cg-text-sm;
        }
        a {
          white-space: nowrap;
          &:hover {
            color: $cg-grey-light;
          }
          i {
            margin-right: 10px;
            font-size: 17px;
          }
        }
      }
      .active {
        color: white;
      }
      .appEditor {
        margin-top: var(--gutter);
      }
    }
    & > .submenu {
      & > .back {
        padding: var(--gutter);
      }
    }
  }
  .back {
    i {
      margin-right: 5px;
      font-size: $font-size-lg;
    }
  }
  .threeColumns {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: var(--gutter);
    .cg-input {
      max-width: 80px;
    }
  }
  .twoColumns {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1fr;
  }
  .oneColumn {
    display: grid;
    grid-gap: calc(var(--gutter) / 2);
    grid-template-columns: 1fr;
  }
}

#fontsBrowserModal {
  & > .header {
    & > .tags {
      display: flex;
      justify-content: flex-start;
      // margin-bottom: var(--gutter);
      .tag {
        font-size: $text-xs;
        margin-right: 5px;
        background-color: transparent;
        border: none;
      }
    }
  }
  .entries {
    display: grid;
    grid-gap: var(--gutter);
    .font {
      border: 1px solid transparent;
      &.favorite,
      &.custom {
        border: 1px solid $cg-grey-light;
      }
      .header {
        display: flex;
        justify-content: space-between;
        .right {
          font-size: $font-size-sm;
          display: flex;
          align-items: center;
          .tags > span {
            margin: 0 calc(var(--gutter) / 1);
          }
        }
      }
    }
    .variation {
      .specimen {
        p {
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  .customFontUploader {
    form {
      .fields,
      .variation {
        margin-bottom: var(--gutter);
      }
    }
  }
}

#fontsStyleCreateModal {
  & > .header {
    margin-bottom: calc(var(--gutter) * 2);
  }
  & > .content {
    .cg-select {
      margin-bottom: calc(var(--gutter) * 2);
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }

  .cg-button {
    margin-top: calc(var(--gutter) * 8);
  }
}

.themeEditorV1 {
  display: grid;
  grid-template-columns: 1fr 6fr;
  .twoColumns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gutter);
    input {
      max-width: 100px;
    }
  }
  .themeMenu {
    padding: var(--gutter);
    & > .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);
    }
    .back {
      margin-bottom: var(--gutter);
      i {
        margin-right: 5px;
        font-size: $font-size-lg;
      }
      a:hover {
        text-decoration: none;
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      a:hover {
        text-decoration: none;
      }
      h2 {
        display: flex;
        i {
          margin-right: var(--gutter);
        }
      }
    }
    .entries {
      margin-bottom: var(--gutter);
      li,
      .entry {
        display: flex;
        align-items: flex-end;
        padding: 5px 0;
        font-size: $cg-text-md;
        @include lessThan(lg) {
          font-size: $cg-text-sm;
        }
        a {
          white-space: nowrap;
          &:hover {
            text-decoration: none;
            color: $cg-grey-light;
          }
          i {
            margin-right: 10px;
            font-size: 17px;
          }
        }
      }
      .active {
        color: white;
      }
      .appEditor {
        margin-top: var(--gutter);
      }
    }
    .submenu {
    }
  }
  & > .right {
    .colorsEditor {
      padding: var(--gutter);
      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: var(--gutter);
      }
      .content {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 1fr 1fr;
      }
      label {
        display: flex;
        flex-direction: column;
        & > * {
          margin-top: 5px;
        }
      }
    }
    .themeSettingsEditor {
      padding: var(--gutter);
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: var(--gutter);
      }
      .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--gutter);
        .cg-input {
          margin-bottom: var(--gutter);
        }
      }
    }
    .cssEditor {
      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--gutter);
        .left {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        p,
        h2 {
          margin: 0;
        }
        button {
          margin: 0;
          margin-left: calc(var(--gutter));
        }
        // margin-bottom: calc(var(--gutter) * 1);
      }
    }
    .fontsEditor {
      & > .content {
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: var(--gutter);
        .left {
          .addStyle {
            margin-top: var(--gutter);
          }
          .style {
            margin: calc(var(--gutter) * 1) 0;
            & > .header {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: calc(var(--gutter) * 1);
              h2 {
                font-size: $font-size-md;
                margin: 0;
              }
            }
            .styleForm {
              & > * {
                margin-bottom: var(--gutter);
              }
              .buttons {
                display: flex;
                justify-content: space-between;
              }
            }
          }
          & > .header {
            h1 {
              margin: 0;
            }
            margin-bottom: calc(var(--gutter) * 2);
          }
        }
      }
      .entries {
        display: grid;
        grid-gap: var(--gutter);
        .font {
          .header {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    .sp-editor {
      height: 85vh;
      color: inherit !important;
    }
  }
}
