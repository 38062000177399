#profile {
  display: grid;
  grid-gap: var(--gutter);
  grid-template-columns: 1fr 5fr;
  .tabs {
    .content {
      position: sticky;
      top: calc(55px + calc(var(--gutter) * 2));
      & > .active button {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
  > .content {
    & > * {
      margin-bottom: var(--gutter);
    }
    .twoGridColumns {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
      margin-bottom: var(--gutter);
    }
    .notifications {
      > .header {
        display: flex;
        justify-content: space-between;
      }
      > * {
        margin-bottom: var(--gutter);
      }
    }
  }
  .table {
    display: grid;
    .row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      :not(:first-child) {
        text-align: center;
      }
    }
  }
}
