@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
$animationTime: 0.2s;

#deliveryModal {
  .packing:not(.mobile) {
    padding: var(--gutter);
    p {
      margin: 0;
    }
    .header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      margin-bottom: calc(var(--gutter) * 2);
      .left {
        display: flex;
        flex-direction: column;
        .cg-button {
          text-align: left;
        }
      }
      .center {
        font-size: $text-lg;
        color: $cg-orange;
        font-weight: 600;
        text-align: center;
      }
      .right {
        display: flex;
        justify-content: flex-end;
        .cg-button {
          margin-left: var(--gutter);
        }
      }
    }
    .content {
      .items {
        margin-bottom: calc(var(--gutter) / 1);
        .item {
          animation: fadeIn $animationTime;
          -webkit-animation: fadeIn $animationTime;
          -moz-animation: fadeIn $animationTime;
          -o-animation: fadeIn $animationTime;
          -ms-animation: fadeIn $animationTime;
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: var(--gutter);
          margin-bottom: calc(var(--gutter) * 2);
          img {
            width: 100%;
          }
          .details {
            display: flex;
            flex-direction: column;
            h2.description {
              font-size: $font-size-lg;
              margin-bottom: calc(var(--gutter) * 2);
            }
            .option {
              text-transform: capitalize;
            }
            .listingEntry {
              .title {
                font-weight: 600;
              }
              .value {
                font-weight: normal;
              }
            }
            .location {
              font-weight: bold;
            }
            & > * {
              margin-bottom: calc(var(--gutter) / 2);
            }
          }
        }
      }
    }
    .footer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      .right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .packing.mobile {
    padding: var(--gutter);
    p {
      margin: 0;
    }
    font-size: $font-size-md;
    .header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      margin-bottom: calc(var(--gutter) * 2);
      .left {
        font-size: $text-lg;
        color: $cg-orange;
        font-weight: 600;
      }
      .right {
        display: flex;
        justify-content: flex-end;
        .cg-button {
          margin-left: var(--gutter);
        }
      }
    }
    .content {
      .items {
        margin-bottom: calc(var(--gutter) / 1);
        .item {
          animation: fadeIn $animationTime;
          -webkit-animation: fadeIn $animationTime;
          -moz-animation: fadeIn $animationTime;
          -o-animation: fadeIn $animationTime;
          -ms-animation: fadeIn $animationTime;
          display: grid;
          grid-template-columns: 1fr 2fr;
          grid-gap: var(--gutter);
          margin-bottom: calc(var(--gutter) * 2);
          img {
            width: 100%;
          }
        }
        .details {
          display: flex;
          flex-direction: column;
          h2.description {
            font-size: $font-size-lg;
            margin-bottom: calc(var(--gutter) * 2);
          }
          .option {
            text-transform: capitalize;
          }
          .listingEntry {
            .title {
              font-weight: 600;
            }
            .value {
              font-weight: normal;
            }
          }
          .location {
            font-weight: bold;
          }
          & > * {
            margin-bottom: calc(var(--gutter) / 2);
          }
        }
      }
    }
    .info {
      text-align: center;
      background-color: #41271d;
      padding: 10px;
      border-radius: $border-radius;
      margin: calc(var(--gutter) * 2) 0;
    }
    .footer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      .sort {
        font-size: $font-size-sm;
      }
      .right {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .markAsShipped {
    padding: var(--gutter);
    form {
      .cg-input,
      .cg-checkbox {
        margin-bottom: calc(var(--gutter) / 2);
      }
      .footer {
        margin-top: calc(var(--gutter) * 2);
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
