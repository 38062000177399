#campaigns {
  .header {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
  }
  section.unable {
    color: black;
    padding: var(--gutter);
    text-align: center;
  }
  .pagination {
    padding: 0 var(--gutter);
  }
  hr {
    margin-top: 0;
  }
  .getStarted {
    text-align: center;
  }
  #campaignsTable {
    display: grid;
    .campaignTableEntry {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--gutter);
      @include respondTo(lg) {
        grid-template-columns: 2fr 1fr 0.5fr 1fr 1fr 0.5fr;
        .address,
        .lastLogin {
          display: none;
        }
      }
      &.header {
        font-weight: bold;
      }
      p {
        margin: 0;
      }
    }
  }
  &.register {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    form {
      display: flex;
      flex-direction: column;
      margin-top: var(--gutter);
      & > * {
        margin-top: var(--gutter);
      }
    }
  }
}

#campaign {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .left {
      display: flex;
      align-items: baseline;
      button {
        border: none;
        margin-left: var(--gutter);
      }
    }
    .right {
      display: flex;
      #send {
        margin-right: var(--gutter);
        button > svg {
          margin: 0 15px;
        }
      }
    }
  }
  #report {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include lessThan(md) {
      grid-template-columns: 1fr;
    }
    #reportHeader {
      margin-top: var(--gutter);
    }
    .stats {
      margin: var(--gutter) 0;
      display: flex;
      justify-content: flex-start;
      h2 {
        margin-right: var(--gutter);
        font-size: $text-lg;
      }
    }
  }
  #preview {
    iframe {
      position: sticky;
      top: calc(var(--navbarHeight) * 1);
      padding: 0;
      width: 100%;
      height: 100vh;
      border: none;
    }
    .header {
      display: flex;
      justify-content: space-between;
    }
  }
  label > * {
    margin-top: calc(var(--gutter) / 2);
  }
  .edit {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include lessThan(md) {
      grid-template-columns: 1fr;
    }
    grid-column-gap: var(--gutter);
    grid-row-gap: var(--gutter);
    .settings {
      margin-bottom: var(--gutter);
      display: grid;
      grid-gap: var(--gutter);
    }
    #left {
      .twoColumns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: var(--gutter);
      }
      .fields {
        .html {
          grid-column: 1 / 3;
        }
        .ql-container {
          min-height: 100px !important;
          background-color: white;
        }
      }
    }
    #right {
      .noItems {
        text-align: center;
        font-weight: bold;
        margin: var(--gutter) 0;
      }
      label {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--gutter);
      }
    }
    .entries {
      margin: calc(var(--gutter) / 2) 0;
      padding: 0;
      .entry {
        &:hover {
          cursor: grab;
        }
        &.dragged {
          border: 2px solid $cg-black;
        }
        .info {
          font-size: $text-md;
        }
        p {
          margin-bottom: 0;
        }
        display: grid;
        grid-template-columns: 20px 40px 5fr 1fr;
        align-items: center;
        grid-gap: var(--gutter);
        margin-bottom: calc(var(--gutter) / 2);
      }
    }
  }
}

#campaignModal {
  #testEmail {
    form {
      display: grid;
      label {
        flex-direction: column;
        display: flex;
        input {
          margin-top: 5px;
        }
      }
    }
  }
  #sendCampaign {
    margin: var(--gutter) 0;
    display: flex;
    flex-direction: column;
    .sendingTime,
    .cg-checkbox {
      margin: calc(var(--gutter) / 2) 0;
    }
    button > svg {
      margin: 0 15px;
    }
  }
}
