#jobs {
  .header {
    h2 {
      margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #jobsTable {
    margin-top: var(--gutter);

    .jobsTableEntry {
      &.header {
        font-weight: bold;
      }
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 2fr 0.5fr;
    }
  }
}
