@import "reset";
@import "./colors";
@import "@common-ground-io/common-assets/styles/scss/variables/texts.scss";
@import "@common-ground-io/common-assets/styles/scss/variables/spacings.scss";
@import "variables";
@import "mixins";
@import "common/index";
@import "components/index";
@import "abstracts/animations";
@import "pages/index";
@import "./iframe";

body {
  margin: 0;
  padding: 0;
  font-family: "Graphik Web", "Helvetica";
  -webkit-font-smoothing: antialiased;
}

html,
body,
#root {
  min-height: 100vh;
  font-size: $text-sm;
  @include respondTo(lg) {
    font-size: $text-md;
  }
}

.noScroll {
  overflow: hidden;
}

.theme-dark,
#loginWrapper.dark,
#modal-dark {
  color: $cg-grey-light;
  background-color: $cg-grey-darkest !important;
  code {
    background-color: $cg-grey-dark;
  }
  .mobileMainMenu,
  #navbarContainer,
  #mobileNavbar {
    color: $cg-grey-light;
    background-color: $cg-grey-darkest;
  }
  .tabList {
    border-bottom: 1px solid $cg-grey-darker;
    .tab {
      &[class*="--selected"]::after {
        display: none;
      }
      &[class*="--selected"] {
        border: none;
        color: $cg-grey-light;
        background: $cg-grey-darker;
      }
    }
  }
}
.theme-light,
#loginWrapper.light,
#modal-light {
  color: $cg-grey-dark;
  background-color: #fff !important;
  code {
    background-color: $cg-grey-light;
  }
  .mobileMainMenu,
  #navbarContainer,
  #mobileNavbar {
    color: $cg-grey-darker;
    background-color: #fff;
  }
  .tabList {
    border-bottom: 1px solid $cg-grey-lightest;
    .tab {
      &[class*="--selected"]::after {
        display: none;
      }
      &[class*="--selected"] {
        border: none;
        background: $cg-grey-lightest;
      }
    }
  }
}

#app {
  display: flex;
  flex-direction: row;
  &.mobile {
    #mainContent {
      padding-top: calc(var(--navbarHeight) * 1);
    }
  }
  min-height: 100vh;
  #editorMenu {
    display: none;
  }
  #mainContent {
    padding-top: calc(var(--navbarHeight) * 1);
    padding-left: var(--menuSize);
    width: 100%;
    flex-basis: 100%;
    transition: padding-left 0.2s ease;
    & > #content {
      padding: var(--gutter);
      padding-bottom: calc(var(--gutter) * 4);
    }
  }
  &.menuIsCollapsed {
    #mainContent {
      padding-left: var(--menuSizeCollapsed);
    }
  }
  &.isInEditorView {
    #editorMenu {
      display: block;
    }
    & > #mainContent > #content {
      padding: 0;
    }
  }
}

.superAdmin {
  color: $cg-yellow !important;
}

section {
  margin-bottom: calc(var(--gutter) * 1);
  &.header {
    button,
    h1,
    h2 {
      margin: 0;
    }
  }
}

.indicator {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
  &.soldOut {
    border-color: $cg-red;
    background-color: $cg-orange;
  }
  &.inStock {
    border-color: $cg-green;
    background-color: $cg-green;
  }
  svg {
    width: 13px;
    height: 13px;
    visibility: hidden;
  }
}

.flexSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.contentHeader {
  margin: var(--gutter) 0;
  display: flex;
  align-items: center;
}

.warning {
  color: $cg-orange;
}
.danger {
  color: $cg-red;
}
