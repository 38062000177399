.languageSelector {
  display: flex;
  justify-content: space-between;
  .languages {
    display: flex;
    justify-content: flex-start;
    button {
      background-color: transparent;
      outline: none;
      border: none;
      font-size: $text-sm;
      &.active {
        text-decoration: underline;
      }
    }
  }
}
