#loginWrapper {
  padding: 0 calc(var(--gutter) * 2);
  @include for-small-phone-only {
    padding: 0 calc(var(--gutter) * 1);
  }
  p {
    line-height: 1.3;
  }
  button {
    padding: 10px 20px;
  }
  button.noStyle {
    font-size: 12px;
  }
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  & > .header {
    margin: calc(var(--gutter) * 3) 0;
    @include for-small-phone-only {
      margin: calc(var(--gutter) * 1) 0;
    }
    @include for-phone-only {
      margin: calc(var(--gutter) * 2) 0;
    }
    display: flex;
    justify-content: center;
    width: 100%;
    img {
      width: 120px;
    }
  }
  .cg-zone {
    padding: 0;
    width: 500px;
    padding: calc(var(--gutter) * 2) calc(var(--gutter) * 4);
    box-sizing: border-box;
    @include for-small-phone-only {
      padding: calc(var(--gutter) * 1);
      width: 100%;
    }
    @include for-phone-only {
      padding: calc(var(--gutter) * 2);
      width: 100%;
    }
  }
  .login {
    border-radius: $border-radius;
    .header {
      h1 {
        font-weight: normal;
        text-align: center;
        font-size: $font-size-lg;
      }
      margin-bottom: calc(var(--gutter) * 3);
      @include for-small-phone-only {
        margin-bottom: calc(var(--gutter) * 2);
      }
    }
    p.message {
      text-align: center;
    }
    .form {
      .formGroup {
        margin-bottom: calc(var(--gutter) * 2);
      }
      .loader {
        margin: 0;
      }
      p.danger {
        text-align: center;
        color: $cg-red;
      }
      .forgotPassword {
        display: flex;
        justify-content: center;
      }
      .submit {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
    hr {
      margin: calc(var(--gutter) * 2) 0;
    }
    .google {
      display: flex;
      justify-content: center;
    }
  }
  .sessionConflict {
    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
    .sessions {
      margin: var(--gutter) 0;
      ul {
        padding-left: 20px;
        li {
          list-style: disc;
          font-weight: bold;
        }
      }
    }
    form {
      button {
        margin-top: 20px;
      }
      display: flex;
      justify-content: center;
    }
    .back {
      margin-top: var(--gutter);
      text-align: center;
      button {
        margin: 0 auto;
      }
    }
  }
}
