$text-community-md: 15px;
$text-community-lg: 22px;
$text-community-xl: 28px;
$gutter-community: 20px;

#communityModal {
  font-size: $text-community-md;
  img {
    border-radius: 10px;
  }
  button.preorder {
    cursor: default;
    color: $cg-grey-lighter;
  }
  .header {
    margin-bottom: calc(var(--gutter) * 2);
    .left {
      display: flex;
      align-items: center;
      align-content: center;
      h3 {
        margin: 0;
      }
      button {
        margin-right: var(--gutter);
        padding: 5px 0;
      }
      i {
        font-size: $text-lg;
      }
      &,
      .configDescription h3 {
        font-size: $text-lg;
      }
      .configDescription img {
        padding: 2px;
        background-color: white;
      }
      .configDescription {
        display: flex;
        align-items: center;
        img {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        & > * {
          margin: 0;
        }
      }
    }
    .right {
      button {
        margin: 0 5px;
        padding: 7px 7px;
        i {
          font-size: $text-lg;
        }
        &.active {
          color: $cg-purple-dark;
        }
      }
      display: flex;
      align-items: center;
    }
    padding: 0;
  }
  .root {
    h2,
    p,
    span {
      margin: 0;
    }
    padding: 0 $gutter-community;
    .content {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr;
      .entry {
        .configDescription {
          display: flex;
          align-items: center;
          img {
            border-radius: 50%;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            padding: 1px;
            background-color: white;
          }
          & > * {
            margin: 0;
          }
          .timeAgo {
            color: $cg-grey-lightest;
          }
        }
        .posts {
          display: grid;
          grid-gap: 5px;
          grid-template-columns: repeat(5, 1fr);
          .post {
            position: relative;
            .item {
              &:hover {
                cursor: pointer;
              }
            }
            &.hover i {
              color: $cg-purple-dark;
            }
            padding: 5px;
            .title {
              font-weight: bold;
            }
            .postHover {
              position: absolute;
              top: 0;
              width: calc(100% - 10px);
              left: 5px;
              display: flex;
              justify-content: space-between;
              .left,
              .right {
                i {
                  font-size: $text-lg;
                }
              }
              button,
              .preorder {
                padding: 7px 5px;
                &.active {
                  color: $cg-purple-dark;
                }
              }
            }
          }
        }
      }
      .favorites {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
  .feedItem {
    padding: 0 $gutter-community;
    .contentAndDescription {
      padding: 0 calc($gutter-community * 2);
      .content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: calc(var(--gutter) * 2);
        margin-bottom: var(--gutter);
        .title {
          font-weight: bold;
          font-size: $text-community-lg;
          margin-bottom: var(--gutter);
        }
        p {
          margin: 0;
        }
        .artistsAndTitle {
          font-weight: normal;
          font-size: $text-lg;
          line-height: 1.1;
          font-size: $text-community-lg;
          margin-bottom: var(--gutter);
        }
        .specs {
          margin-bottom: var(--gutter);
          line-height: 1.2;
        }
        .tracklist {
          .track {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2px;
            .left {
              display: flex;
              align-items: center;
              align-content: center;
              .position {
                margin-right: 10px;
              }
            }
            button.player {
              margin-right: 5px;
              opacity: 0.2;
              padding: 5px 2px;
              &.playable {
                opacity: 1;
              }
            }
          }
        }
      }
      & > .description > p {
        font-size: $text-community-xl;
      }
    }
  }
}

#createPostModal {
  .content {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1fr;
  }
}
