#register {
  @include lessThan(md) {
    width: 100%;
  }
  @include moreThan(md) {
    width: 640px;
  }
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: calc(var(--gutter) * 2);
  .content {
    padding: calc(var(--gutter) * 3) calc(var(--gutter) * 12);
    @include lessThan(lg) {
      padding: calc(var(--gutter) * 3);
    }
  }
  .twoColumns {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: calc(var(--gutter) * 2);
    a {
      width: 20%;
    }
  }
  .title {
    text-align: center;
    margin-bottom: calc(var(--gutter) * 2);
  }
  #introduction,
  #finalize,
  #details,
  #verify,
  #passwordReset {
    display: flex;
    justify-content: center;
    flex-direction: column;
    & > .content {
      & > .logins {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        & > * {
          margin: calc(var(--gutter) * 1);
        }
        .top {
          width: 100%;
          .elements {
            display: flex;
            flex-direction: column;
            & > * {
              margin-bottom: calc(var(--gutter) * 1);
            }
          }
        }
      }
      .google,
      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  #details {
    .content {
      padding: calc(var(--gutter) * 3) calc(var(--gutter) * 8);
      @include lessThan(md) {
        padding: calc(var(--gutter) * 1);
      }
    }
  }

  #finalize {
    .content {
      padding: calc(var(--gutter) * 3) calc(var(--gutter) * 6);
      @include lessThan(md) {
        padding: calc(var(--gutter) * 1);
      }
    }
  }

  #introduction {
    & > .content {
      & > .logins {
        hr {
          margin: calc(var(--gutter) * 1) 0;
        }
      }
      .sso {
        p {
          text-align: center;
        }
        .buttons {
          & > * {
            margin-bottom: calc(var(--gutter) / 2);
          }
          .apple {
            padding: 0px;
            a {
              &:hover {
                text-decoration: none;
              }
            }
            button.appleButton {
              padding: 5px 20px;
              display: flex;
              justify-content: center;
              background-color: black;
              align-items: center;
              color: white;
              border: 1px solid black;
              width: 100%;
              border-radius: 5px;
              font-size: 18px;
              text-decoration: none;
              img {
                width: 40px;
              }
            }
            #appleid-signin {
              cursor: pointer;
              height: 40px;
              padding: 2px 10px;
            }
          }
        }
      }
    }
  }

  #verify {
    .codes {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      input {
        font-size: 1.5em;
        width: 2em;
        text-align: center;
        flex: 1 0 1em;
        padding: var(--spacing);
        border-radius: calc(var(--spacing) / 2);
        color: var(--text1);
        background: $cg-grey;
        border: 0;
        border: 4px solid transparent;
        &:invalid {
          box-shadow: none;
        }
        &:focus {
          outline: none;
        }
      }
    }
  }

  #legalFooter {
    padding: calc(var(--gutter) / 2);
    font-size: $text-sm;
    display: flex;
    justify-content: space-between;
    @include lessThan(md) {
      flex-direction: column;
      justify-content: flex-start;
      .left {
        margin-bottom: var(--gutter);
        & > * {
          margin-right: var(--gutter);
        }
      }
    }
    @include moreThan(md) {
      .left > * {
        margin: 5px 10px;
      }
    }
  }
}
