#messenger {
  & > .content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    @include lessThan(lg) {
      grid-template-columns: 1fr;
    }

    .left {
      display: grid;
      .channel {
        padding: var(--gutter);
      }
    }
    & > .right {
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > .header {
        background-color: $cg-grey-dark;
        padding: 5px;
        text-align: center;
      }
      .messages {
        max-height: 70vh;
        overflow: scroll;
        padding: 0 var(--gutter);
        display: grid;
        .message {
          .header {
            display: flex;
            justify-content: center;
            font-size: $text-xs;
          }
          & > .content {
            display: flex;
            .text {
              background-color: $cg-green-dark;
              padding: 10px var(--gutter);
              border-radius: var(--gutter);
              width: fit-content;
              max-width: 50%;
            }
          }
          &.me {
            .content {
              justify-content: flex-end;
              .text {
                background-color: $cg-blue;
              }
            }
          }
        }
        .from {
          font-style: italic;
        }
      }
      .newMessage {
        padding: var(--gutter);
        background-color: $cg-grey-dark;
      }
    }
  }
}
