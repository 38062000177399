$xs: 320px !default;
$sm: 576px !default;
$md: 768px !default;
$lg: 1152px !default;
$xl: 1440px !default;
$wide: 1800px !default;

$breakpoints: (
  "xs": $xs,
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
  "wide": $wide
) !default;

@mixin respondTo($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Minimum Screen Width
@mixin respondToMin($screen) {
  @if $screen == xs {
    @media only screen and (min-width: $xs) {
      @content;
    }
  } @else if $screen == sm {
    @media only screen and (min-width: $sm) {
      @content;
    }
  } @else if $screen == md {
    @media only screen and (min-width: $md) {
      @content;
    }
  } @else if $screen == lg {
    @media only screen and (min-width: $lg) {
      @content;
    }
  }
}

// Maximum Screen Width
@mixin respondToMax($screen) {
  @if $screen == xs {
    @media only screen and (max-width: $xs - 0.1) {
      @content;
    }
  } @else if $screen == sm {
    @media only screen and (max-width: $sm - 0.1) {
      @content;
    }
  } @else if $screen == md {
    @media only screen and (max-width: $md - 0.1) {
      @content;
    }
  } @else if $screen == lg {
    @media only screen and (max-width: $lg - 0.1) {
      @content;
    }
  }
}

@mixin for-small-phone-only {
  @media (max-width: $xs) {
    @content;
  }
}
@mixin for-phone-only {
  @media (max-width: $sm) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $sm) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $md) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $lg) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: $wide) {
    @content;
  }
}

@mixin lessThan($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin moreThan($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
