#googleOauth {
  .header {
    margin-bottom: calc(var(--gutter) * 4);
  }
  .error {
    text-align: center;
  }
  .content {
    ul {
      list-style-type: disc;
      padding-left: 20px;
      li {
        button {
          font-size: $font-size-md;
        }
        padding: 10px 0;
      }
    }
  }
}
