#announcement {
  height: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &.danger {
    background-color: $cg-red;
  }
  &.warning {
    background-color: $cg-orange;
  }
  &.information {
    background-color: $cg-green;
  }
  text-align: center;
  @include lessThan(md) {
    padding-left: 40px;
  }
}
