#orders {
  .header {
    display: flex;
    flex-direction: row;
    @include lessThan(lg) {
      flex-direction: column;
    }
    justify-content: space-between;
    h1 {
      margin: 0;
    }
    .left,
    .right {
      display: flex;
      align-items: center;
      @include lessThan(lg) {
        justify-content: space-between;
        margin-bottom: var(--gutter);
      }
      button.primary {
        margin-left: var(--gutter);
      }
    }
  }

  .orderSearch {
    width: 100%;
    @include moreThan(md) {
      max-width: 350px;
    }
  }

  #orderFilters {
    margin-bottom: var(--gutter);
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr;
    @include respondTo(md) {
      grid-template-columns: 1fr 1fr;
    }
    @include respondTo(lg) {
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: unset;
      grid-auto-flow: column;
      grid-auto-columns: auto;
    }
    .column {
      display: flex;
      flex-direction: column;
      & > * {
        margin-bottom: calc(var(--gutter) / 1);
      }
    }
    #dates {
      #dateFilters {
        display: grid;
        grid-gap: var(--gutter);
        grid-template-columns: 1fr 1fr;
        #dateSelectors {
          display: grid;
          grid-gap: var(--gutter);
        }
        .dateShortcuts {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-column-gap: calc(var(--gutter) / 4);
          grid-row-gap: calc(var(--gutter) / 4);
          button {
            margin: 0;
            width: 100%;
            &.active {
              font-weight: bold;
            }
          }
        }
      }
      #sortAndOrder {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .sort,
        .order {
          .alert {
            display: flex;
            align-items: center;
            color: $cg-red;
            font-weight: light;
            svg {
              margin-right: 5px;
            }
          }
          .dateRadioInputs {
            .dateSortInput {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
  #orderEntries {
    padding: 0;
  }
  .pagination.orders {
    .top {
      display: grid;
      align-items: center;
      grid-gap: calc(var(--gutter) / 2);
      margin-bottom: calc(var(--gutter) / 2);
      @include for-tablet-landscape-up {
        display: flex;
        justify-content: space-between;
      }
      .info {
        color: $cg-blue;
      }
      .left {
        color: $cg-grey;
        & > * {
          margin-right: 10px;
        }
        display: flex;
      }
      .middle {
        display: flex;
        align-items: center;
        button {
          margin-left: var(--gutter);
        }
      }
      .right {
        display: flex;
        justify-content: space-between;
        .pagesRecap {
          color: $cg-grey;
          margin-right: var(--gutter);
        }
        ul {
          display: flex;
          li {
            padding: 0 5px;
          }
        }
        .disabled {
          cursor: default;
          color: $cg-grey;
        }
      }
    }
  }
}

#orderTable {
  display: grid;
  .orderTableEntry {
    border: 1px solid transparent;
    &:hover {
      cursor: pointer;
      border: 1px solid $cg-blue;
    }
  }
  .orderTableItem {
    .highlight {
      font-weight: bold;
    }
    &.merged,
    &.cancelled {
      color: grey;
    }
    &.header {
      font-weight: bold;
    }
    display: grid;
    padding: calc(var(--gutter) / 2);
    .id {
      a:visited > span {
        color: $cg-grey;
      }
    }
    .description {
      display: none;
      .descriptionItem.unavailable {
        text-decoration: line-through;
      }
    }
    grid-template-columns: 1fr 1fr 1fr 1fr 0.5fr;
    @include respondTo(md) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.5fr;
    }
    @include respondTo(lg) {
      .description {
        display: block;
      }
      grid-template-columns: 0.6fr 0.5fr 2.5fr 0.4fr 0.4fr 0.5fr 0.2fr;
    }
    .status {
      svg {
        color: $cg-grey-lightest;
        font-size: $font-size-lg;
        margin-right: calc(var(--gutter) / 4);
      }
    }
    grid-column-gap: calc(var(--gutter) / 4);
    .origin {
      & > * {
        margin-bottom: 2px;
      }
      .pdf {
        margin: 0;
        a:hover {
          text-decoration: none;
        }
        button {
          display: flex;
          align-items: center;
          padding: 0px;
          svg {
            margin-right: 3px;
            width: 15px;
            height: 15px;
          }
        }
      }
      span img {
        max-width: 13px;
        margin-right: calc(var(--gutter) / 4);
        height: auto;
      }
    }
    p {
      margin: 0;
      &.note {
        font-weight: bold;
        text-decoration: underline;
        margin-top: calc(var(--gutter) / 2);
        font-size: $font-size-sm;
      }
    }
    .fulfilled {
      color: $cg-grey-lighter;
    }
    .unfulfilled {
      color: $cg-orange;
    }
    .customer {
      .icons {
        svg {
          margin: 5px 5px;
        }
        .contacted {
          svg {
            color: $cg-grey-lighter;
          }
        }
      }
    }
  }
}

#orderPage {
  display: grid;
  p {
    margin: 0;
  }
  & > .content {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(150px, 3fr);
    @include respondToMax(lg) {
      grid-template-columns: 1fr;
      .gross,
      .image {
        display: none;
      }
    }
    grid-gap: var(--gutter);
    .guestWarning {
      font-weight: normal;
      margin: calc(var(--gutter) / 2) 0;
    }
    #contentLeft {
      .cg-zone {
        margin-bottom: var(--gutter);
        .subSection {
          margin: var(--gutter) 0;
        }
        .sectionHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          p {
            font-weight: bold;
          }
          .editButton {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
          }
        }
      }
      .inputAndButton {
        display: flex;
        justify-content: flex-start;
        button {
          flex-grow: 1;
          margin: 0;
          height: 30px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: -2px;
          min-width: 60px;
          .loader {
            margin: 0;
          }
        }
        input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          flex-grow: 40;
          height: 30px;
        }
      }
      #notes {
        p:first-child {
          font-weight: bold;
        }
        display: grid;
        grid-row-gap: calc(var(--gutter) / 4);
        .note {
          padding: 0 0;
          .noteHeader {
            font-size: $font-size-sm;
            font-style: italic;
            margin: 0;
            display: flex;
            justify-content: space-between;
          }
        }
      }
      #instructions {
        p {
          font-style: italic;
        }
      }
      #lock {
        button {
          width: 100%;
        }
      }
      #shipping {
        .proceedToDelivery {
          display: flex;
        }
      }
      #trackingAndEmailConfirmation {
        #trackingLink {
          display: flex;
          flex-direction: row;
          align-items: center;
          img {
            height: 30px;
            width: auto;
          }
          h4 {
            margin: 0;
            margin-left: calc(var(--gutter) / 2);
          }
        }
        #emailConfirmation {
          margin: calc(var(--gutter) / 2) 0;
          .date {
            font-size: $font-size-sm;
            font-style: italic;
            font-weight: bold;
          }
          .recipient {
            display: flex;
            justify-content: space-between;
          }
        }
      }
      #billing {
        .userSearchInput {
          width: 100%;
        }
        #invoiceEmail {
          margin-bottom: var(--gutter);
          display: block;
          #invoiceRecipients {
            .recipientEntry {
              margin-top: calc(var(--gutter) / 2);
              border-bottom: 0.5px solid $cg-grey-lightest;
              .date {
                font-size: $font-size-sm;
                font-style: italic;
                font-weight: bold;
              }
            }
          }
          .recipient {
            margin-bottom: calc(var(--gutter) / 2);
            display: flex;
            justify-content: space-between;
          }
        }
        #pdf {
          display: flex;
          flex-direction: column;
          button {
            svg {
              margin-right: calc(var(--gutter) / 2);
            }
            .loader {
              margin: 0;
              margin-left: 1em;
            }
          }
        }
      }
      #paymentMethods,
      #refunds {
        .editButton {
          background-color: transparent;
          border: none;
          padding: 0;
          margin: 0;
        }
        .payWithcreditNote {
          margin-top: var(--gutter);
        }
        .paymentMethod {
          text-transform: capitalize;
          display: flex;
          justify-content: space-between;
          margin-top: -1px;
          & > .giftCard {
            .searchWithResults {
              background-color: white;
              .inputSearch {
                background-color: white;
                width: 100%;
              }
            }
            .info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 100%;
              padding: 0 calc(var(--gutter) / 2);
            }
          }
          input {
            margin-left: -1px;
          }
        }
      }
      .addressEdit {
        form {
          display: grid;
          grid-gap: var(--gutter);
        }
        h4 {
          text-transform: capitalize;
        }
      }
      #cancel,
      #generateCreditNote {
        button {
          margin-top: calc(var(--gutter) / 2);
          width: 100%;
        }
      }
      #logs {
        .history {
          padding: 0 !important;
        }
      }
    }
    #contentRight {
      grid-row-start: 1;
      @include respondTo(lg) {
        grid-row-start: auto;
      }
      section#header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respondToMax(lg) {
          flex-direction: column;
        }
        .tooltipContainer {
          font-size: $font-size-lg;
        }
        h2 {
          margin: 0;
        }

        #discogsStatus {
          display: flex;
          img {
            max-width: 20px;
            margin-right: 5px;
            height: auto;
          }
        }
      }
      #shippingPriceSuggestion {
        font-weight: bold;
        color: $cg-red;
        margin-bottom: var(--gutter);
      }
      #addAndMerge {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: var(--gutter);
        #mergeForm {
          display: grid;
          grid-template-columns: 10fr 1fr;
          button {
            margin: 0;
            height: 100%;
          }
        }
        #addItem {
          margin-bottom: var(--gutter);
        }
      }
      #totalsContainer {
        display: flex;
        justify-content: flex-end;
        margin-top: calc(var(--gutter) / 2);
        #totals {
          display: flex;
          flex-direction: column;
          text-align: right;
          p.creditNote {
            margin-top: var(--gutter);
          }
          p.total {
            font-size: $font-size-lg;
            margin: var(--gutter) 0;
            font-weight: bold;
          }
          p.amountDue {
            font-size: $font-size-md;
            margin: var(--gutter) 0;
          }
          button {
            margin: 0;
          }
        }
      }
      #items {
        display: grid;
        grid-row-gap: calc(var(--gutter) / 8);
        .item,
        #itemsHeader {
          &.unavailable {
            .description p {
              text-decoration: line-through;
            }
          }
          &:nth-child(even) {
            background-color: $cg-grey-lightest;
          }
          .inline {
            display: flex;
            align-items: center;
          }
          .net > input {
            max-width: 70px;
          }
          .taxes {
            .taxEntry {
              align-items: center;
              display: flex;
            }
            .add,
            .taxEntry {
              text-align: center;
              button {
                padding: 0;
                font-size: $font-size-md;
                background-color: transparent;
                border: none;
              }
            }
          }
          button {
            margin: 0;
          }
          .image > i {
            font-size: $text-xl;
          }
          .description {
            display: flex;
            small {
              color: #aaa;
            }
            align-items: center;
          }
          .quantity {
            p.moreThanOne {
              font-weight: bold;
            }
            input {
              max-width: 60px;
            }
          }
          display: grid;
          grid-template-columns: 4fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
          @include respondTo(lg) {
            grid-template-columns: 0.3fr 3fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr;
          }
          grid-gap: calc(var(--gutter) / 2);
          align-items: center;
          font-weight: normal;
        }
      }
      #messagesContainer {
        #newMessage {
          display: grid;
          grid-gap: var(--gutter);
          .templateControls {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: var(--gutter);
            align-items: center;
          }
          .saveTemplate {
            display: grid;
            grid-template-columns: 1fr;
            align-items: center;
            margin-bottom: var(--gutter);
          }
          .saveTemplate > *,
          .cg-checkbox {
            margin-bottom: var(--gutter);
          }
          .file,
          .saveTemplate {
            max-width: 50%;
          }
        }
        .loader {
          margin: 0 var(--gutter);
        }
        #orderMessages {
          & > * {
            margin-bottom: calc(var(--gutter) / 1);
          }
          .messageTracker {
            .message {
              .header {
                font-weight: bold;
                margin-bottom: calc(var(--gutter) / 2);
                svg {
                  width: 12px;
                  margin-right: calc(var(--gutter) / 2);
                }
              }
            }
          }
          .message {
            .content {
              margin-bottom: calc(var(--gutter) / 1);
            }
            .attachments {
              i {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

#orderModal {
  &.editLine {
    .lineEditor {
      padding: var(--gutter);
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--gutter);
      @include lessThan(lg) {
        flex-direction: column;
      }
      & > * {
        display: flex;
        flex-direction: column;
        margin-bottom: var(--gutter);
      }
      .discountEntry {
        display: flex;
        align-items: flex-end;
        button {
          max-height: 35px;
        }
      }
    }
    .modalHeader {
      .left {
        display: flex;
        button.removeLine {
          padding: 0 inherit;
          color: $cg-red;
        }
      }
    }

    button.cancel {
      border: none;
    }
    .imageAndDescription {
      margin-bottom: var(--gutter);
      display: flex;
      .description {
        .main {
          font-size: $cg-text-md;
        }
        p:not(.main) {
          font-size: $cg-text-xs;
          margin: 0;
        }
        padding: 0 var(--gutter);
      }
      img {
        max-width: 80px;
        @include lessThan(lg) {
          display: none;
        }
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
    }
  }
  &.markAsUnavailable {
    form {
      margin-top: var(--gutter);
      button[type="submit"] {
        margin-top: var(--gutter);
      }
      label,
      input {
        margin-top: 5px;
      }
      label {
        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
      .note {
        display: flex;
        flex-direction: column;
      }
      display: flex;
      flex-direction: column;
    }
  }

  &.cancelOrder {
    form {
      margin-top: var(--gutter);
      button[type="submit"] {
        margin-top: var(--gutter);
      }
      label,
      input {
        margin-top: 5px;
      }
      label {
        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
      .note {
        display: flex;
        flex-direction: column;
      }

      #refundAmountAndOrigin {
        margin-top: 5px;
        display: grid;
        grid-gap: 5px;
        grid-template-columns: 1fr 1fr;
        input {
          width: 100%;
          margin: 0;
        }
      }
      #discogsModalStatuses {
        display: flex;
        flex-direction: column;
        .header {
          img {
            max-width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          h3 {
            margin: 0;
          }
          align-items: center;
          display: flex;
          flex-direction: row;
        }
      }
      display: flex;
      flex-direction: column;
    }
  }
  &.sendInvoice {
    .header {
      margin-bottom: calc(var(--gutter) * 2);
    }
    .content {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 1fr;
      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        embed {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        form {
          display: grid;
          grid-gap: calc(var(--gutter) * 1);
          .submit,
          .templateControls {
            margin-top: var(--gutter);
          }
          .buttons {
            margin-top: var(--gutter);
            button {
              margin: 0;
              margin-left: var(--gutter);
            }
            display: flex;
            justify-content: flex-end;
          }
          .templateControls {
            display: grid;
            grid-gap: calc(var(--gutter) / 2);
          }
          .saveTemplate {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            margin-bottom: var(--gutter);
          }
        }
      }
    }
  }
}

#editMessageTemplates {
  .header {
    display: flex;
    align-items: center;
  }
  .content {
    & > * {
      margin-bottom: var(--gutter);
    }
  }
}

#orderPaymentMethodsModal,
#orderRefundMethodsModal {
  .header {
    justify-content: space-between;
    margin-bottom: calc(var(--gutter) * 2);
    h2 {
      margin: 0;
    }
  }
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: var(--gutter);
    padding-bottom: calc(var(--gutter) * 1);
    @include for-phone-only() {
      grid-template-columns: 1fr;
    }
    .left {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include for-phone-only() {
        grid-template-columns: 1fr 1fr 1fr;
      }
      grid-gap: 3px;
      .paymentMean {
        border: 1px solid transparent;
        &.active {
          border: 1px solid $cg-blue;
        }
        padding: 0;
        button {
          width: 100%;
          height: 100%;
          border: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: transparent;
          padding: 15px 5px;
        }
        i {
          font-size: $font-size-xxl;
          margin-bottom: var(--gutter);
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .amountDue {
        display: flex;
        justify-content: space-between;
        margin: 0;
      }
      .methods {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--gutter);
        margin-bottom: calc(var(--gutter) * 2);
      }
    }
    .newPayment {
      margin-bottom: var(--gutter);
      form > * {
        input {
          flex-grow: 1;
        }
        margin-bottom: var(--gutter);
      }
    }
  }
  .paymentMethod {
    p {
      margin-bottom: 0;
      font-weight: bold;
    }
    display: grid;
    grid-gap: var(--gutter);
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 0.5fr;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
}
#addVoucherModal {
  .discountSelector form,
  .redeemType {
    display: flex;
    flex-direction: row;
    justify-content: center;
    label {
      margin: 0 var(--gutter);
    }
  }

  form.mainForm {
    & > * {
      margin-bottom: var(--gutter);
    }

    .valueDiscount {
      .options {
        & > * {
          margin-bottom: var(--gutter);
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        label {
          display: flex;
          flex-direction: row;
        }
      }
      .value {
        input {
          width: 100%;
        }
      }
    }
    .voucherDiscount {
      & > * {
        margin-bottom: var(--gutter);
      }
      .voucherInfo {
        p {
          margin: 0;
        }
        border: 1px dashed white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
      }
      .note {
        display: flex;
        flex-direction: column;
      }
      display: flex;
      flex-direction: column;
    }
    .creditNoteDiscount {
      p.noCredit {
        margin: var(--gutter) 0;
      }
      .creditNote {
        margin: 10px 0;
        display: flex;
        button {
          margin: 0;
          height: 35px;
          margin-right: 10px;
        }
        button.selected {
          background-color: $cg-grey-lighter;
        }
        input {
          min-width: 150px;
        }
      }
    }
  }
}

#checkoutPage {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .buttons button {
      margin-left: var(--gutter);
    }
  }
  #emptyCheckout {
    margin-top: var(--gutter);
    text-align: center;
  }
  #checkouts {
    display: grid;
    grid-gap: var(--gutter);
    .checkout {
      &:not(.complete) {
        border-style: dashed;
      }
      &.complete {
        border-color: $cg-green;
        text-align: center;
        h2,
        button {
          margin: var(--gutter);
        }
      }
      h2 {
        margin: 0;
      }
      border: 1px solid $cg-black;
      .header {
        .dirty {
          border-style: dashed;
          display: flex;
          margin-left: 5px;
          align-items: center;
        }
        &,
        .right,
        .left {
          small {
            margin-left: var(--gutter);
          }
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            margin-left: var(--gutter);
          }
        }
      }
      .checkoutContent {
        display: grid;
        grid-template-columns: 1fr;
        @include respondTo(lg) {
          grid-template-columns: 1fr 2.5fr;
        }
        grid-gap: var(--gutter);
        .right {
          .items {
            margin-top: var(--gutter);
            display: grid;
            grid-template-columns: auto;
            grid-row-gap: calc(var(--gutter) / 2);
            align-items: center;
            .noItems {
              text-align: center;
              font-weight: bold;
              margin: var(--gutter) 0;
            }
            .item {
              &.header {
                font-weight: bold;
              }
              display: grid;
              grid-template-columns: 0.8fr 5fr 1fr 1fr 1fr 1fr;
              grid-column-gap: calc(var(--gutter) / 4);
              align-items: flex-start;
              .flexRow {
                display: flex;
                flex-direction: row;
                align-items: center;
              }
              .flexColumn {
                display: flex;
                flex-direction: column;
              }
              .column {
                display: flex;
                flex-direction: column;
              }
              input {
                max-width: 80px;
              }
              img {
                max-width: 40px;
                height: auto;
              }
              p,
              h3 {
                margin: 0;
              }
            }
          }
          hr {
            margin: var(--gutter) 0;
          }
          .newCheckoutfooter {
            margin-top: var(--gutter);
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-content: space-between;
            .totalsAndSubmit {
              p {
                margin: 0;
              }
              justify-self: end;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              button {
                margin-top: var(--gutter);
                align-self: flex-end;
                width: 140px;
              }
              .shipping {
                display: flex;
                flex-direction: column;
                margin-bottom: var(--gutter);
              }
              small {
                span {
                  margin-right: 5px;
                }
              }
              small,
              .cg-checkbox {
                margin: calc(var(--gutter) / 2) 0;
              }
            }
            .voucher {
              .voucherInfo {
                p,
                button {
                  margin: 0;
                }
                padding: 5px;
                border: 1px dashed $cg-black;
                display: flex;
                justify-content: space-between;
                align-items: center;
                button {
                  border: none;
                  font-size: $font-size-lg;
                  background-color: transparent;
                }
                .valid {
                  border-color: $cg-green;
                  outline: none;
                }
                .invalid {
                  border-color: $cg-red;
                  outline: none;
                }
              }
              h3 {
                margin: 0;
                margin-bottom: calc(var(--gutter) / 4);
              }
            }
          }
          .availableVouchers {
            button {
              margin-right: 5px;
            }
          }
        }
        .left {
          .paymentMethods {
            margin-top: var(--gutter);
            .header {
              display: flex;
              justify-content: space-between;
              .tooltipFlex {
                display: flex;
              }
            }
          }
          .user {
            margin-top: var(--gutter);
            padding: 0;
            h3 {
              margin-bottom: calc(var(--gutter) / 2);
            }
            .userSelectOrCreate {
              display: flex;
              flex-direction: column;
              & > * {
                margin-bottom: var(--gutter);
              }
            }
            .buyerContainer {
              p {
                font-weight: bold;
              }
              .nameAndRemove {
                display: flex;
                justify-content: space-between;
              }
              margin-top: calc(var(--gutter) / 2);
              display: flex;
              justify-content: space-between;
              align-content: center;
              flex-direction: column;
            }
          }
        }
      }
      .paymentMethod {
        text-transform: capitalize;
        display: grid;
        grid-template-columns: auto 4fr;
        margin-top: -1px;
        & > .giftCard {
          .searchWithResults {
            background-color: white;
            .inputSearch {
              background-color: white;
              width: 100%;
            }
          }
          .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            padding: 0 calc(var(--gutter) / 2);
          }
        }
        input {
          margin-left: -1px;
        }
        button.icon {
          background-color: transparent;
          border-radius: 0px;
          border: solid 1px inherit;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          height: 35px;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          svg {
            width: 25px;
            height: auto;
          }
        }
      }
    }
  }
}

#openCheckouts {
  #checkoutEntries {
    .checkout {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr 3fr 1fr;
      justify-content: space-between;
      padding: calc(var(--gutter) / 2);
      .item {
        p {
          margin: 0;
        }
      }
    }
  }
}

#checkoutPage,
#orderPage {
  .moreInfo {
    display: inline-block;
    margin: 0;
    margin-right: 5px;
    font-size: $font-size-lg;
  }
}

$grid-spacing: 5px;
$report-text-xl: 25px;
#salesReportModal {
  padding: calc(var(--gutter) * 2);
  .header {
    .left {
      display: flex;
      align-items: baseline;
      & > * {
        margin: 0;
      }
      h2 {
        margin-right: var(--gutter);
      }
    }
  }
  #salesReport {
    .salesReportContent {
      display: grid;
      grid-gap: $grid-spacing;
      .paymentMethods,
      .amounts {
        & .entry {
          border: none;
          border-radius: 5px;
          padding: var(--gutter);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-size: $text-md;
          .title {
            text-align: left;
            margin-bottom: var(--gutter);
          }
          .value {
            text-align: right;
          }
        }
      }
      .valueInline {
        display: flex;
        justify-content: space-between;
        margin-bottom: $grid-spacing;
      }
      .amounts {
        display: grid;
        grid-gap: $grid-spacing;
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
          "grand subtotal shippingBeforeTaxes"
          "grand grandBeforeTaxes secondHandProfit"
          "origins itemTypes taxes";
        @include lessThan(lg) {
          grid-template-columns: repeat(2, 1fr);
          grid-template-areas:
            "grand subtotal"
            "grandBeforeTaxes shippingBeforeTaxes  "
            "secondHandProfit origins "
            "itemTypes taxes";
        }
        .entry {
          .value {
            font-size: $report-text-xl;
          }
        }
      }
      .paymentMethods > .entries {
        display: grid;
        grid-gap: $grid-spacing;
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        @include lessThan(lg) {
          grid-template-columns: repeat(3, 1fr);
        }
        justify-content: space-between;
        .entry {
          .title {
            text-transform: capitalize;
          }
        }
      }
    }
    .sendButton {
      margin-top: var(--gutter);
      text-align: center;
    }
  }

  .sendReport {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 1fr;
    .left {
      text-align: center;
      button {
        margin-top: calc(var(--gutter) * 4);
      }
      embed {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      form {
        display: grid;
        grid-gap: calc(var(--gutter) * 1);
        .submit,
        .templateControls {
          margin-top: var(--gutter);
        }
        .buttons {
          margin-top: var(--gutter);
          button {
            margin: 0;
            margin-left: var(--gutter);
          }
          display: flex;
          justify-content: flex-end;
        }
        .templateControls {
          display: grid;
          grid-gap: calc(var(--gutter) / 2);
        }
        .saveTemplate {
          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;
          margin-bottom: var(--gutter);
        }
      }
    }
  }
}
