#support {
  .notice {
    padding: var(--gutter);
    text-align: center;
    margin: var(--gutter) 0;
    color: $cg-red;
    border: 2px dashed $cg-red;
    border-radius: 0.3rem;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #tickets {
    .ticket {
      display: grid;
      grid-template-columns: 0.3fr 1fr 2fr 1fr 0.5fr;
      grid-gap: var(--gutter);
      p {
        margin: 0;
      }
      .open,
      .pending {
        color: $cg-red;
      }
      .closed,
      .resolved {
        color: $cg-green;
      }
      border-radius: $border-radius;
      p {
        word-wrap: break-word;
      }
    }
    & > * {
      margin-bottom: calc(var(--gutter) * 2);
    }
  }
}

#ticketModal {
  padding: var(--gutter);
  .header {
    align-items: center;
    h2 {
      margin: 0;
    }
    display: flex;
    justify-content: space-between;
  }
  label {
    @include respondToMax(lg) {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    & > * {
      margin-top: 5px;
    }
    &.inline {
      flex-direction: row;
      input {
        margin: 0;
        margin-right: 5px;
      }
    }
  }
  label.urgent {
    display: flex;
    flex-direction: row;
  }
  .preferencesFields {
    & > * {
      margin-bottom: var(--gutter);
    }
  }
  .submitted {
    text-align: center;
  }
  .notice {
    .center {
      margin: calc(var(--gutter) * 2);
      text-align: center;
    }
    ul {
      padding: var(--gutter);
      li {
        padding: 5px;
        list-style: circle;
      }
    }
  }
}

#ticket {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--gutter);
  }
  .content {
    display: grid;
    grid-gap: var(--gutter);
    grid-template-columns: 1fr 2fr;
    .left {
      padding: var(--gutter);
      position: sticky;
      top: 0;
      section {
        margin-bottom: calc(var(--gutter) * 2);
        &.ticketDescription {
          border: 1px solid $cg-grey-lightest;
          padding: var(--gutter);
        }
      }
      form {
        margin: var(--gutter) 0;
        label {
          display: flex;
          flex-direction: column;
          margin: 1em 0;
        }
      }
    }

    .right {
      .message {
        padding: var(--gutter);
        margin-bottom: var(--gutter);
        img {
          max-width: 100%;
        }
        &.fromSupport {
          border: 1px solid $cg-blue;
        }
        .freshdesk_quote {
          display: none;
        }
        .attachment {
          a {
            text-decoration: underline;
            display: flex;
            flex-direction: column;
          }
          margin-bottom: var(--gutter);
        }
      }
    }
  }
}
