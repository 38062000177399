#discogsInventory {
  &.loading {
    text-align: center;
    margin-top: 100px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
    }
    margin-bottom: calc(var(--gutter) * 2);
  }
  .exists {
    margin: 5px 0;
    svg {
      margin-right: 5px;
    }
    align-items: center;
    color: green;
  }
  .inventory {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr;
      .liner {
        p {
          margin: 0;
        }
        padding: 0 var(--gutter);
      }
      .entry {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 0.5fr 3fr 3fr 2fr;
        @include for-phone-only {
          grid-template-columns: 1fr 2fr;
        }
        .content {
          display: flex;
          flex-direction: column;
          p {
            margin: 0;
          }
          .row {
            display: flex;
            justify-content: space-between;
          }
          button {
            margin-bottom: var(--gutter);
          }
        }
        .discrepancy,
        .exists {
          font-weight: bold;
        }
        .viewInInventory {
          margin-top: var(--gutter);
        }
      }
    }
  }
  .orders {
    .entries {
      display: grid;
      grid-gap: var(--gutter);
      grid-template-columns: 1fr;
      .liner {
        p {
          margin: 0;
        }
        padding: 0 var(--gutter);
      }
      .entry {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 5fr 1fr 1.5fr;
        @include for-phone-only {
          grid-template-columns: 1fr;
        }

        .discrepancy,
        .exists {
          font-weight: bold;
        }
        .viewInInventory {
          margin-top: var(--gutter);
        }
      }
    }
  }
}
